import React, { useCallback } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { CabinetLayout } from '../layouts/Cabinet';

import { useAxios } from '../utils/hooks';

export default () => {
  const { keycloak } = useKeycloak();
  const axiosInstance = useAxios('http://localhost:5000'); // see https://github.com/panz3r/jwt-checker-server for a quick implementation
  const callApi = useCallback(() => {
    axiosInstance.get('/jwt/decode');
  }, [axiosInstance]);

  return (
    <CabinetLayout>
      <div>User is {!keycloak.authenticated ? 'NOT ' : ''} authenticated</div>

      <button className="btn btn-primary" type="button" onClick={callApi}>
        Call API
      </button>
    </CabinetLayout>
  );
};
