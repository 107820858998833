import React, { useEffect, useState, useRef, useCallback, memo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useAxios, useMetric } from 'utils/hooks';
import {
  NEUX_URL,
  METRIC_EVENT_TYPE,
  METRIC_EVENT_BATCH,
  GAME_MAP_TYPE,
  SEQUENCE_LIFECYCLE_VALUES,
  QUESTION_TYPE,
} from 'utils/constants';
import { formatQuestionUrlWithNeux } from 'utils/format';
import { getMilisecondCount } from 'utils/helper';
import TimeTracker from 'components/TimeTracker';
import TileCaching from 'services/TileCaching';
import { store, connect, actions, selector } from 'store';
import PlaySequence from 'components/PlaySequence';
import { remapSequence, getMappedTileByAnswer } from './utils';

const PlayingSequence = ({
  listChoice,
  sequence,
  sequencePlayGuid,
  onClose,
  isKeepingGameMode,
  keyboardMappings,
}) => {
  if (!sequencePlayGuid || isEmpty(sequence)) return null;
  const { sendMetricData } = useMetric(METRIC_EVENT_BATCH.MC_SEQUENCE);
  const [sequenceData, setSequenceData] = useState(null);
  const [game, setGame] = useState(null);
  const [sequenceGuid, setSequenceGuid] = useState('');
  const [listQuestions, setListQuestions] = useState([]);
  const timerRef = useRef();
  const sequenceGameIndex = useRef(0);
  const questionGameIndex = useRef(0);
  const gameIndex = useRef(0);
  const axiosInstance = useAxios(NEUX_URL);
  const questionTimeoutRef = useRef();

  const fetchQuestions = useCallback(
    async questionGuid => {
      try {
        const resp = await axiosInstance.get(
          formatQuestionUrlWithNeux(questionGuid),
        );
        setListQuestions(
          resp?.data?.questions.map(item => ({
            timeout: resp?.data?.timeout,
            ...item,
          })) || [],
        );
      } catch (error) {
        setListQuestions([]);
      }
    },
    [axiosInstance],
  );

  const toggleClock = useCallback(
    newGame => {
      if (!newGame) {
        timerRef.current.setSequenceClock(false);
        return;
      }
      switch (newGame?.type) {
        case GAME_MAP_TYPE.WELCOME: {
          timerRef.current.togglePositionClock();
          timerRef.current.setSequenceClock(true);
          timerRef.current.toggleSequence(true);
          break;
        }
        case GAME_MAP_TYPE.LEVEL: {
          timerRef.current.togglePositionClock();
          timerRef.current.setQuestionClock(false);
          timerRef.current.setGameClock(true);
          break;
        }
        case GAME_MAP_TYPE.QUESTION: {
          timerRef.current.togglePositionClock();
          timerRef.current.setQuestionClock(true);
          break;
        }
        case GAME_MAP_TYPE.FINISH: {
          timerRef.current.togglePositionClock();
          timerRef.current.setGameClock(false);
          timerRef.current.setQuestionClock(false);
          break;
        }
        default: {
          break;
        }
      }
    },
    [timerRef.current, sequenceData],
  );

  const startLevelMetric = useCallback(
    ({ newGame }) => {
      switch (newGame?.type) {
        case GAME_MAP_TYPE.WELCOME: {
          // timerRef.current.toggleLevel();
          sendMetricData({
            eventType: METRIC_EVENT_TYPE.WELCOME_MAP_STARTED,
            welcomeMapGuid: newGame?.welcome_map_guid,
            sequenceGuid,
            sequencePlayGuid,
            bpm: Number(sequenceData?.bpm),
            gameSequenceIndex: Number(sequenceGameIndex.current),
            transportPos: timerRef.current.clockValues?.position,
            sequencePosMs: getMilisecondCount(
              timerRef.current?.singleDate?.sequence,
            ),
          });
          break;
        }
        case GAME_MAP_TYPE.LEVEL: {
          // timerRef.current.toggleLevel();
          sendMetricData({
            eventType: METRIC_EVENT_TYPE.LEVEL_MAP_STARTED,
            levelMapGuid: newGame?.level_map_guid,
            timingsGuid: newGame?.timings_guid,
            gameGuid: newGame?.guid,
            gameSequenceIndex: Number(sequenceGameIndex.current),
            sequenceGuid,
            sequencePlayGuid,
            bpm: Number(sequenceData?.bpm),
            transportPos: timerRef.current.clockValues?.position,
            sequencePosMs: getMilisecondCount(
              timerRef.current?.singleDate?.sequence,
            ),
          });
          break;
        }
        case GAME_MAP_TYPE.SLIDER_POS_START_BEGIN: {
          sendMetricData({
            eventType: METRIC_EVENT_TYPE.SLIDER_POS_START_BEGIN,
            levelMapGuid: newGame?.level_map_guid,
            timingsGuid: newGame?.timings_guid,
            gameGuid: newGame?.guid,
            gameSequenceIndex: Number(sequenceGameIndex.current),
            sequenceGuid,
            sequencePlayGuid,
            tileGuid: newGame?.tileGuid,
            tileDirection: newGame?.tileDirection,
            transportPos: timerRef.current.clockValues?.position,
            sequencePosMs: getMilisecondCount(
              timerRef.current?.singleDate?.sequence,
            ),
          });
          break;
        }
        case GAME_MAP_TYPE.SLIDER_POS_END_BEGIN: {
          sendMetricData({
            eventType: METRIC_EVENT_TYPE.SLIDER_POS_END_BEGIN,
            levelMapGuid: newGame?.level_map_guid,
            timingsGuid: newGame?.timings_guid,
            gameGuid: newGame?.guid,
            gameSequenceIndex: Number(sequenceGameIndex.current),
            sequenceGuid,
            sequencePlayGuid,
            tileGuid: newGame?.tileGuid,
            tileDirection: newGame?.tileDirection,
            transportPos: timerRef.current.clockValues?.position,
            sequencePosMs: getMilisecondCount(
              timerRef.current?.singleDate?.sequence,
            ),
          });
          break;
        }
        case GAME_MAP_TYPE.QUESTION: {
          // timerRef.current.toggleQuestion();
          sendMetricData({
            eventType: METRIC_EVENT_TYPE.QUESTION_MAP_STARTED,
            questionsMapGuid: newGame?.level_map_guid,
            gameGuid: newGame?.game_guid,
            gameSequenceIndex: sequenceGameIndex.current,
            sequenceGuid,
            sequencePlayGuid,
            bpm: Number(sequenceData?.bpm),
            transportPos: timerRef.current.clockValues?.position,
            sequencePosMs: getMilisecondCount(
              timerRef.current?.singleDate?.sequence,
            ),
          });
          break;
        }
        case GAME_MAP_TYPE.QA: {
          // timerRef.current.toggleQuestionAnswer();
          sendMetricData({
            eventType: METRIC_EVENT_TYPE.QUESTION_ASKED,
            questionsMapGuid: newGame?.level_map_guid,
            questionGuid: newGame?.questions_guid,
            questionIndex: questionGameIndex.current,
            questionName: newGame?.description,
            gameGuid: newGame?.game_guid,
            gameSequenceIndex: sequenceGameIndex.current,
            sequenceGuid,
            sequencePlayGuid,
            bpm: Number(sequenceData?.bpm),
            transportPos: timerRef.current.clockValues?.position,
            sequencePosMs: getMilisecondCount(
              timerRef.current?.singleDate?.sequence,
            ),
          });
          break;
        }
        case GAME_MAP_TYPE.FINISH: {
          // timerRef.current.toggleLevel();
          sendMetricData({
            eventType: METRIC_EVENT_TYPE.FINISH_MAP_STARTED,
            finishMapGuid: newGame?.finish_map_guid,
            sequenceGuid,
            sequencePlayGuid,
            bpm: Number(sequenceData?.bpm),
            gameSequenceIndex: Number(sequenceGameIndex.current),
            transportPos: timerRef.current.clockValues?.position,
            sequencePosMs: getMilisecondCount(
              timerRef.current?.singleDate?.sequence,
            ),
          });
          break;
        }
        default: {
          break;
        }
      }
    },
    [
      sequenceGuid,
      sequencePlayGuid,
      sequenceData,
      sequenceGameIndex.current,
      questionGameIndex.current,
      timerRef.current,
    ],
  );

  const finishLevelMetric = useCallback(
    ({ newGame, key }) => {
      const keyPressed = (keyboardMappings && keyboardMappings[key]) || key;
      switch (newGame?.type) {
        case GAME_MAP_TYPE.WELCOME: {
          // timerRef.current.toggleLevel();
          sendMetricData({
            eventType: METRIC_EVENT_TYPE.WELCOME_MAP_ENDED,
            welcomeMapGuid: newGame?.level_map_guid,
            sequenceGuid,
            sequencePlayGuid,
            keyPressed,
            transportPos: timerRef.current.clockValues?.position,
            gameSequenceIndex: Number(sequenceGameIndex.current),
            sequencePosMs: getMilisecondCount(
              timerRef.current?.singleDate?.sequence,
            ),
          });
          break;
        }
        case GAME_MAP_TYPE.LEVEL: {
          // timerRef.current.toggleLevel();
          sendMetricData({
            eventType: METRIC_EVENT_TYPE.LEVEL_MAP_ENDED,
            levelMapGuid: newGame?.level_map_guid,
            timingsGuid: newGame?.timings_guid,
            gameGuid: newGame?.guid,
            gameSequenceIndex: Number(sequenceGameIndex.current),
            sequenceGuid,
            sequencePlayGuid,
            transportPos: timerRef.current.clockValues?.position,
            sequencePosMs: getMilisecondCount(
              timerRef.current?.singleDate?.sequence,
            ),
          });
          break;
        }
        case GAME_MAP_TYPE.SLIDER_POS_START_FINISH: {
          sendMetricData({
            eventType: METRIC_EVENT_TYPE.SLIDER_POS_START_FINISH,
            levelMapGuid: newGame?.level_map_guid,
            timingsGuid: newGame?.timings_guid,
            gameGuid: newGame?.guid,
            gameSequenceIndex: Number(sequenceGameIndex.current),
            sequenceGuid,
            sequencePlayGuid,
            tileGuid: newGame?.tileGuid,
            tileDirection: newGame?.tileDirection,
            transportPos: timerRef.current.clockValues?.position,
            sequencePosMs: getMilisecondCount(
              timerRef.current?.singleDate?.sequence,
            ),
          });
          break;
        }
        case GAME_MAP_TYPE.SLIDER_POS_END_FINISH: {
          sendMetricData({
            eventType: METRIC_EVENT_TYPE.SLIDER_POS_END_FINISH,
            levelMapGuid: newGame?.level_map_guid,
            timingsGuid: newGame?.timings_guid,
            gameGuid: newGame?.guid,
            gameSequenceIndex: Number(sequenceGameIndex.current),
            sequenceGuid,
            sequencePlayGuid,
            tileGuid: newGame?.tileGuid,
            tileDirection: newGame?.tileDirection,
            transportPos: timerRef.current.clockValues?.position,
            sequencePosMs: getMilisecondCount(
              timerRef.current?.singleDate?.sequence,
            ),
          });
          break;
        }
        case GAME_MAP_TYPE.QUESTION: {
          // timerRef.current.toggleQuestion();
          sendMetricData({
            eventType: METRIC_EVENT_TYPE.QUESTION_MAP_ENDED,
            questionsMapGuid: newGame?.level_map_guid,
            gameGuid: newGame?.game_guid,
            gameSequenceIndex: sequenceGameIndex.current,
            sequenceGuid,
            sequencePlayGuid,
            transportPos: timerRef.current.clockValues?.position,
            sequencePosMs: getMilisecondCount(
              timerRef.current?.singleDate?.sequence,
            ),
          });
          break;
        }
        case GAME_MAP_TYPE.QA: {
          // timerRef.current.toggleQuestionAnswer();
          const answerTile = key ? getMappedTileByAnswer(key) : '';
          sendMetricData({
            eventType: METRIC_EVENT_TYPE.QUESTION_ANSWERED,
            timingsGuid: newGame?.timings_guid,
            questionsMapGuid: newGame?.level_map_guid,
            questionGuid: newGame?.questions_guid,
            questionIndex: questionGameIndex.current,
            questionName: newGame?.description,
            gameGuid: newGame?.game_guid,
            gameSequenceIndex: sequenceGameIndex.current,
            sequenceGuid,
            sequencePlayGuid,
            keyPressed,
            timedOut: Boolean(newGame?.timed_out),
            transportPos: timerRef.current.clockValues?.position,
            sequencePosMs: getMilisecondCount(
              timerRef.current?.singleDate?.sequence,
            ),
            answerTile,
          });
          break;
        }
        case GAME_MAP_TYPE.FINISH: {
          // timerRef.current.toggleLevel();
          store.dispatch(actions.clearSliderObjects());
          store.dispatch(actions.clearSliderAnswers());
          store.dispatch(actions.clearKeyboardMappings());
          sendMetricData({
            eventType: METRIC_EVENT_TYPE.FINISH_MAP_ENDED,
            finishMapGuid: newGame?.level_map_guid,
            sequenceGuid,
            sequencePlayGuid,
            keyPressed,
            transportPos: timerRef.current.clockValues?.position,
            gameSequenceIndex: Number(sequenceGameIndex.current),
            sequencePosMs: getMilisecondCount(
              timerRef.current?.singleDate?.sequence,
            ),
            bpm: Number(sequenceData?.bpm),
          });
          break;
        }
        default: {
          break;
        }
      }
    },
    [
      sequenceGuid,
      sequencePlayGuid,
      sequenceGameIndex.current,
      timerRef.current,
      keyboardMappings,
    ],
  );

  const trackSliderObject = useCallback(
    ({ tileGuid, tileDirection, isPosStart, isBegin }) => {
      if (isBegin) {
        startLevelMetric({
          newGame: {
            ...game,
            tileGuid,
            tileDirection,
            type: isPosStart
              ? METRIC_EVENT_TYPE.SLIDER_POS_START_BEGIN
              : METRIC_EVENT_TYPE.SLIDER_POS_END_BEGIN,
          },
        });
      } else {
        finishLevelMetric({
          newGame: {
            ...game,
            tileGuid,
            tileDirection,
            type: isPosStart
              ? METRIC_EVENT_TYPE.SLIDER_POS_START_FINISH
              : METRIC_EVENT_TYPE.SLIDER_POS_END_FINISH,
          },
        });
      }
    },
    [startLevelMetric, finishLevelMetric, game],
  );

  const handleSetGameQuestion = useCallback(
    _game => {
      if (listQuestions.length > 0) {
        questionGameIndex.current += 1;
        const qs = listQuestions[0];
        let questionText = qs.description;
        if (qs?.type !== QUESTION_TYPE.TILE_LIST) {
          const typeSplit = qs?.type.split('.');
          const value = typeSplit[typeSplit.length - 1];
          /* eslint-disable-next-line no-useless-escape */
          const regexText = /\{([0-9a-zA-Z\.]+)\}/g;
          const replaceText = qs?.description?.replace(regexText, value);
          questionText = replaceText;
        }
        const newGame = {
          ..._game,
          question_text: questionText,
          question: qs,
          type: GAME_MAP_TYPE.QA,
          timings_guid: null, // trigger reset animationTypes
        };
        setGame(newGame);
        startLevelMetric({ newGame });
        const remainQuestions = [...listQuestions.slice(1)];
        setListQuestions(remainQuestions);
        if (qs.timeout) {
          questionTimeoutRef.current = setTimeout(() => {
            // eslint-disable-next-line no-use-before-define
            finishLevelMetric({ newGame });
            if (remainQuestions.length > 0) {
              handleSetGameQuestion(newGame);
            } else {
              gameIndex.current += 1;
              // eslint-disable-next-line no-use-before-define
              startPlaySequence(sequenceData);
            }
          }, qs.timeout);
        }
      }
    },
    // eslint-disable-next-line no-use-before-define
    [listQuestions, handleCloseGame],
  );

  const clearSequenceChoice = () => {
    store.dispatch(actions.clearSequenceChoice());
  };

  const startPlaySequence = useCallback(
    async _sequence => {
      clearSequenceChoice();
      if (
        _sequence?.result?.length > 0 &&
        gameIndex.current < _sequence?.result?.length
      ) {
        const newGame = _sequence.result[gameIndex.current];
        if (newGame?.type === GAME_MAP_TYPE.LEVEL) {
          sequenceGameIndex.current += 1;
        }
        toggleClock(newGame);
        startLevelMetric({
          newGame,
        });
        switch (newGame.type) {
          case GAME_MAP_TYPE.LEVEL: {
            if (newGame?.questions_guid) {
              await fetchQuestions(newGame?.questions_guid);
            }
            setGame(newGame);
            break;
          }
          case GAME_MAP_TYPE.QUESTION: {
            questionGameIndex.current = 0;
            handleSetGameQuestion(newGame);
            break;
          }
          default: {
            setGame({
              level_map_guid:
                newGame.welcome_map_guid || newGame.finish_map_guid,
              type: newGame.type,
            });
            break;
          }
        }
      } else {
        setGame(null);
        toggleClock();
      }
    },
    [listQuestions, axiosInstance, startLevelMetric, toggleClock],
  );

  const handleCloseGame = useCallback(() => {
    if (
      sequenceData?.result?.length > 0 &&
      gameIndex.current < sequenceData?.result?.length
    ) {
      const newGame = sequenceData.result[gameIndex.current];
      finishLevelMetric({
        newGame,
      });
      gameIndex.current += 1;
      startPlaySequence(sequenceData);
    } else {
      if (gameIndex.current > 0) onClose();
      gameIndex.current = 0;
      setSequenceData(null);
      timerRef.current.clearClockValue();
    }
  }, [listQuestions, finishLevelMetric, onClose, sequenceData]);

  const onQuestionKeyDown = useCallback(
    evt => {
      if (game.type === GAME_MAP_TYPE.LEVEL) return;
      if (listChoice.indexOf(evt?.key?.toUpperCase()) !== -1) {
        clearTimeout(questionTimeoutRef.current);
        finishLevelMetric({
          newGame: game,
          key: evt?.key,
        });
        if (listQuestions.length > 0) {
          handleSetGameQuestion(game);
        } else {
          if (game.type === GAME_MAP_TYPE.QA) {
            finishLevelMetric({
              newGame: {
                ...game,
                type: GAME_MAP_TYPE.QUESTION,
              },
            });
          }
          gameIndex.current += 1;
          startPlaySequence(sequenceData);
        }
      }
    },
    [listQuestions, game, listChoice, finishLevelMetric, keyboardMappings],
  );

  useEffect(() => {
    startPlaySequence(sequenceData);
  }, [sequenceData]);

  useEffect(() => {
    if (sequence) {
      const data = remapSequence(sequence);
      TileCaching.setCachesFromSequence(data);
      gameIndex.current = 0;
      setSequenceGuid(sequence?.guid);
      setSequenceData(data);
    }
  }, [sequence]);

  useEffect(() => {
    window.addEventListener('keydown', onQuestionKeyDown);
    return () => {
      window.removeEventListener('keydown', onQuestionKeyDown);
    };
  }, [listChoice, listQuestions, onQuestionKeyDown]);

  return (
    <>
      <TimeTracker
        showStopWatch={
          sequenceData?.lifecycle === SEQUENCE_LIFECYCLE_VALUES.DEBUG
        }
        ref={timerRef}
        bpm={sequenceData?.bpm}
      />
      <PlaySequence
        map={game?.level_map_guid}
        timing={game?.timings_guid}
        questions={game?.questions_guid}
        question={game?.question}
        questionText={game?.question_text}
        isKeepingGameMode={isKeepingGameMode}
        clearSequenceChoice={clearSequenceChoice}
        handleCloseGame={handleCloseGame}
        trackSliderObject={trackSliderObject}
      />
    </>
  );
};

const mapStateToProps = state => ({
  listChoice: selector.getSequenceListChoice(state),
  keyboardMappings: selector.getKeyboardMappings(state),
});

export default connect(mapStateToProps)(memo(PlayingSequence));
