import * as actions from './actions';

const initialValue = {
  sequence: {
    listChoices: [' '],
    sliderObjects: {},
    listSliderAnswers: [],
    keyboardMappings: {},
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.ADD_SEQUENCE_CHOICE: {
      if (state.sequence.listChoices.indexOf(action.payload.choice) > -1) {
        return state;
      }
      const newListChoice = [
        ...state.sequence.listChoices,
        action.payload.choice,
      ];
      return {
        ...state,
        sequence: {
          ...state.sequence,
          listChoices: newListChoice,
        },
      };
    }
    case actions.ADD_SEQUENCE_CHOICES: {
      return {
        ...state,
        sequence: {
          ...state.sequence,
          listChoices: [' ', ...action.payload.listChoices],
        },
      };
    }
    case actions.CLEAR_SEQUENCE_CHOICE: {
      return {
        ...state,
        sequence: {
          ...state.sequence,
          listChoices: [...initialValue.sequence.listChoices],
        },
      };
    }
    case actions.ADD_SLIDER_OBJECTS: {
      return {
        ...state,
        sequence: {
          ...state.sequence,
          sliderObjects: {
            ...action?.payload?.object,
          },
        },
      };
    }
    case actions.CLEAR_SLIDER_OBJECTS: {
      return {
        ...state,
        sequence: {
          ...state.sequence,
          sliderObjects: {},
        },
      };
    }
    case actions.ADD_SLIDER_ANSWERS: {
      return {
        ...state,
        sequence: {
          ...state.sequence,
          listSliderAnswers: action?.payload?.listSliderAnswers,
        },
      };
    }
    case actions.CLEAR_SLIDER_ANSWERS: {
      return {
        ...state,
        sequence: {
          ...state.sequence,
          listSliderAnswers: [],
        },
      };
    }
    case actions.ADD_KEYBOARD_MAPPINGS: {
      return {
        ...state,
        sequence: {
          ...state.sequence,
          keyboardMappings: action?.payload?.keyboardMappings,
        },
      };
    }
    case actions.CLEAR_KEYBOARD_MAPPINGS: {
      return {
        ...state,
        sequence: {
          ...state.sequence,
          keyboardMappings: {},
        },
      };
    }
    default: {
      return state;
    }
  }
};

class Store {
  constructor() {
    this.state = { ...initialValue };
    this.cb = [];
  }

  dispatch = action => {
    const newState = reducer(this.state, action);
    if (newState !== this.state) {
      this.state = newState;
      this.cb.forEach(func => {
        func(this.state);
      });
    }
  };

  getState() {
    return { ...this.state };
  }

  subscribe = handler => {
    this.cb.push(handler);
    return this.unsubscribe(handler);
  };

  unsubscribe = handler => () => {
    this.cb = this.cb.filter(func => func !== handler);
  };
}

const store = new Store();

export default store;
