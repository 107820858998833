import React, { useState, useEffect, useCallback, useRef } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Fade } from '@material-ui/core';
import { toast } from 'react-toastify';
import { connect, selector } from 'store';
import Tiled from 'tiled';
import {
  formatTimingUrlWithNeux,
  formatQuestionUrlWithNeux,
  formatMapUrlWithNeux,
} from 'utils/format';
import ToneTransport from 'services/ToneTransport';
import { useAxios } from 'utils/hooks';
import { NEUX_URL, QUESTION_TYPE } from 'utils/constants';
import './PlayGame.scss';

const PlayGame = ({
  map,
  timing,
  questionUrl,
  questionMapUrl,
  onStopGame,
  listChoice,
}) => {
  const [mapUrl, setMapUrl] = useState(null);
  const [timingUrl, setTimingUrl] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [resultGames, setResultGames] = useState([]);
  const [listQuestions, setListQuestions] = useState([]);
  const timeDataRef = useRef(null);
  const mapQuestionRef = useRef(null);
  const questionTimeoutRef = useRef(null);
  const toastId = useRef(null);
  const eventId = useRef(null);
  const axiosInstance = useAxios(NEUX_URL); // see https://github.com/panz3r/jwt-checker-server for a quick implementation

  const hideMenuTab = () => {
    document.getElementById('main-navbar').style.display = 'none';
    document.getElementsByClassName('main-menu')[0].style.display = 'none';
    document.getElementsByClassName('app-content')[0].style.marginLeft = '0px';
    document.getElementsByClassName('fixed-navbar')[0].style.paddingTop = '0px';
    document.getElementsByClassName('content-wrapper')[0].style.padding = '0px';
    document.getElementsByClassName('content-wrapper')[0].style.height =
      '100vh';
    document.getElementsByClassName('content-wrapper')[0].style.overflow =
      'hidden';
    if (document.getElementById('game-outside')) {
      document.getElementById('game-outside').style.display = 'none';
    }
  };

  const openMenuTab = () => {
    document.getElementById('main-navbar').style.display = 'block';
    document.getElementsByClassName('main-menu')[0].style.display = 'block';
    document.getElementsByClassName('app-content')[0].style.marginLeft =
      '240px';
    if (document.getElementsByClassName('fixed-navbar')) {
      document.getElementsByClassName('fixed-navbar')[0].style.paddingTop =
        '4.57rem';
    }
    document.getElementsByClassName('content-wrapper')[0].style.padding =
      '2.2rem';
    document.getElementsByClassName('content-wrapper')[0].style.height =
      'calc(100vh - 84px)';
    document.getElementsByClassName('content-wrapper')[0].style.overflow =
      'auto';
    if (document.getElementById('game-outside')) {
      document.getElementById('game-outside').style.display = 'block';
    }
  };

  const fetchQuestions = useCallback(
    async questionGuid => {
      try {
        const resp = await axiosInstance.get(
          formatQuestionUrlWithNeux(questionGuid),
        );
        await setListQuestions(resp?.data?.questions || []);
        return resp?.data?.questions || [];
      } catch {
        await setListQuestions([]);
        return [];
      }
    },
    [axiosInstance],
  );

  const handlePlayRequest = async (_mapUrl, _timingUrl, _questionUrl) => {
    hideMenuTab();
    if (_timingUrl) {
      setTimingUrl(formatTimingUrlWithNeux(_timingUrl));
    }
    setMapUrl(formatMapUrlWithNeux(_mapUrl));
    let mapQuestions = [];
    if (_questionUrl) {
      mapQuestions = await fetchQuestions(_questionUrl);
    }
    if (_timingUrl) {
      /* eslint-disable-next-line no-use-before-define */
      setEndEvent(formatTimingUrlWithNeux(_timingUrl), mapQuestions);
    }
  };

  const setNextGameQuestion = useCallback(
    _mapQuestions => {
      const mapQuestions = _mapQuestions || listQuestions;
      if (mapQuestions.length > 0) {
        let questionObj = {};
        const qs = mapQuestions[0];
        if (qs?.type === QUESTION_TYPE.TILE_LIST) {
          questionObj = { ...qs, question_text: qs.description };
        } else {
          const typeSplit = qs?.type.split('.');
          const value = typeSplit[typeSplit.length - 1];
          /* eslint-disable-next-line no-useless-escape */
          const regexText = /\{([0-9a-zA-Z\.]+)\}/g;
          const replaceText = qs?.description?.replace(regexText, value);
          questionObj = { ...qs, question_text: replaceText };
        }
        setResultGames(prevValue => [...prevValue, { question: questionObj }]);
        setCurrentQuestion(questionObj);
        const remainQuestions = [...mapQuestions.slice(1)];
        setListQuestions(remainQuestions);
        handlePlayRequest(questionMapUrl);
        if (questionObj?.timeout) {
          questionTimeoutRef.current = setTimeout(() => {
            /* eslint-disable-next-line no-use-before-define */
            onEndEvent(remainQuestions);
          }, questionObj?.timeout);
        }
      }
    },
    [listQuestions, questionMapUrl],
  );

  const clearData = () => {
    setTimingUrl(null);
    setMapUrl(null);
    setListQuestions([]);
    setCurrentQuestion(null);
    setResultGames([]);
  };

  const onCloseGame = useCallback(() => {
    /* eslint-disable-next-line no-console */
    toast.dismiss(toastId.current);
    ToneTransport.getInstance().clearSchedule(eventId.current);
    clearData();
    openMenuTab();
    onStopGame();
  }, [resultGames]);

  const onEndEvent = mapQuestions => {
    if (mapQuestions && mapQuestions.length > 0) {
      setNextGameQuestion(mapQuestions);
    } else {
      onCloseGame();
    }
  };

  const onStartGesture = async () => {
    toast.dismiss(toastId.current);
    ToneTransport.getInstance().clearEventEnding();
    eventId.current = ToneTransport.getInstance().startEventEnding(
      timeDataRef.current,
      () => onEndEvent(mapQuestionRef.current),
    );
  };

  const onWaitGesture = () => {
    document.addEventListener('click', onStartGesture);
  };

  const setEndEvent = async (_timingUrl, mapQuestions) => {
    try {
      const { data } = await axiosInstance.get(_timingUrl);
      let checkStatus = false;

      if (data) {
        timeDataRef.current = data;
        mapQuestionRef.current = mapQuestions;
        // check tone status
        ToneTransport.getInstance().checkToneStatus(() => {
          checkStatus = true;
        });
        setTimeout(async () => {
          if (!checkStatus) {
            toastId.current = toast.error(
              <div style={{ fontSize: '17px' }}>
                Please click to start game timing
              </div>,
              {
                autoClose: 60000,
              },
            );
            // start wait gesture
            onWaitGesture();
          } else {
            ToneTransport.getInstance().clearEventEnding();
            eventId.current = ToneTransport.getInstance().startEventEnding(
              data,
              () => onEndEvent(mapQuestions),
            );
          }
        }, 100);
      }
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.log(error);
    }
  };

  const setGameResult = result => {
    setResultGames(value => {
      if (result?.keyResult && value.length > 0) {
        const latestGame = value.slice(-1)[0];
        latestGame.result = result.keyResult || latestGame.result;
      }
      return value;
    });
  };

  const onQuestionKeyDown = useCallback(
    evt => {
      if (!currentQuestion) return;
      if (listChoice.indexOf(evt.key.toUpperCase()) !== -1) {
        clearTimeout(questionTimeoutRef.current);
        if (listQuestions.length > 0) {
          setGameResult({ keyResult: evt.key });
          setNextGameQuestion();
        } else {
          setGameResult({ keyResult: evt.key });
          // close game
          onCloseGame();
        }
      }
    },
    [listQuestions, listChoice, currentQuestion],
  );

  useEffect(() => {
    if (map && axiosInstance) {
      handlePlayRequest(map, timing, questionUrl);
    }
  }, [map, timing, questionUrl, axiosInstance]);

  useEffect(() => {
    window.removeEventListener('keydown', onQuestionKeyDown);
    window.addEventListener('keydown', onQuestionKeyDown);
    return () => {
      window.removeEventListener('keydown', onQuestionKeyDown);
    };
  }, [listChoice, listQuestions]);

  useEffect(() => {
    return () => {
      document.removeEventListener('click', onStartGesture);
      onCloseGame();
    };
  }, []);
  return (
    <>
      <CssBaseline />
      <Fade
        in={Boolean(mapUrl)}
        timeout={{
          enter: 1000,
          exit: 0,
        }}
        mountOnEnter
        unmountOnExit
      >
        <div className="tileWrapper">
          <Tiled
            mapUrl={mapUrl}
            timingUrl={timingUrl}
            axiosInstance={axiosInstance}
            tileText={currentQuestion?.question_text}
            currentQuestion={currentQuestion}
          />
        </div>
      </Fade>
    </>
  );
};

const mapStateToProps = state => ({
  listChoice: selector.getSequenceListChoice(state),
});

export default connect(mapStateToProps)(PlayGame);
