import React, { useCallback, useEffect, useState, useMemo } from 'react';
import classNames from 'classnames';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAxios } from 'utils/hooks';
import { NEUX_URL, SEQUENCE_LIFECYCLE_VALUES } from 'utils/constants';
import PageHeader, { ButtonType } from 'components/PageHeader';

import { Select, MenuItem } from '@material-ui/core';
import { ChromePicker } from 'react-color';
import * as giIcon from 'react-icons/gi';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-dracula';
import './UpdateGame.scss';

export default () => {
  const { id } = useParams();
  const history = useHistory();

  const [height, setHeight] = useState(
    document?.body?.getBoundingClientRect()?.height,
  );
  const [idGame, setIdGame] = useState(id);
  const [loading, setLoading] = useState(false);
  const [listTimings, setListTimings] = useState([]);
  const [listQuestions, setListQuestions] = useState([]);
  const [listMaps, setListMaps] = useState([]);
  const [timingUrl, setTimingUrl] = useState('');
  const [questionUrl, setQuestionUrl] = useState('');
  const [mapUrl, setMapUrl] = useState('');
  const [iconName, setIconName] = useState('');
  const [game, setGame] = useState({
    name: '',
    lifecycle: '',
  });
  const [iconColor, setIconColor] = useState('');
  const [questionsMap, setQuestionsMap] = useState('');
  const [listQuestionMaps, setListQuestionMaps] = useState([]);
  const axiosInstance = useAxios(NEUX_URL);

  const onMapChange = event => {
    setMapUrl(event.target.value);
  };

  const onTimingChange = event => {
    setTimingUrl(event.target.value);
  };

  const onQuestionChange = event => {
    setQuestionUrl(event.target.value);
  };

  const onChangeIconColor = color => {
    setIconColor(color.hex);
  };

  const onChangeQuestionsMap = event => {
    setQuestionsMap(event.target.value);
  };

  const isDisableSelect = useMemo(() => {
    return (
      loading ||
      (idGame &&
        (game?.lifecycle === SEQUENCE_LIFECYCLE_VALUES.ARCHIVE ||
          game?.lifecycle === SEQUENCE_LIFECYCLE_VALUES.PUBLISHED))
    );
  }, [loading, game?.lifecycle]);

  const iconList = React.useMemo(() => {
    const listIconNames = Object.keys(giIcon);
    return listIconNames.map(icon => {
      const IconComponent = giIcon[icon];
      return (
        <div key={icon}>
          <IconComponent
            className={classNames('game-select-icon', {
              'selected-game-icon': icon === iconName,
            })}
            onClick={() => setIconName(icon)}
          />
          <div className="icon-name">
            {icon.length > 10 ? `${icon.slice(0, 10)}...` : icon}
          </div>
        </div>
      );
    });
  }, [iconName]);

  const handleResizeEditor = () => {
    if (document?.body?.getBoundingClientRect()?.height !== height) {
      setHeight(document?.body?.getBoundingClientRect()?.height);
    }
  };

  const onSaveGame = useCallback(
    async values => {
      try {
        setLoading(true);
        if (idGame) {
          await axiosInstance.post('sc/game', {
            icon: iconName,
            icon_color: iconColor,
            guid: idGame,
            questions_map_guid: questionsMap,
            questions_guid: questionUrl,
            timings_guid: timingUrl,
            level_map_guid: mapUrl,
            ...values,
          });
        } else {
          const { data } = await axiosInstance.put('sc/game', {
            icon: iconName,
            icon_color: iconColor,
            questions_guid: questionUrl,
            timings_guid: timingUrl,
            level_map_guid: mapUrl,
            questions_map_guid: questionsMap,
            ...values,
          });
          setIdGame(data?.guid);
          window.history.replaceState(null, '', `/game/${data.guid}`);
        }
        setGame({
          name: values?.name,
          lifecycle: values?.lifecycle,
        });
        setLoading(false);
        toast.success('Saved');
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [
      iconName,
      axiosInstance,
      iconColor,
      idGame,
      questionsMap,
      questionUrl,
      timingUrl,
      mapUrl,
    ],
  );

  const fetchEssentialData = async () => {
    try {
      setLoading(true);
      const respTiming = await axiosInstance.get('/sc/timings/search');
      if (respTiming?.data?.timings) {
        setListTimings(respTiming?.data?.timings);
      }
      const respQuestion = await axiosInstance.get('/sc/questions/search');
      if (respQuestion?.data?.questions) {
        setListQuestions(respQuestion?.data?.questions);
      }
      const respMap = await axiosInstance.get('/sc/map/search/level/desc');
      if (respMap?.data?.map) {
        setListMaps(respMap?.data?.map);
      }
      const respQuestionMaps = await axiosInstance.get(
        '/sc/map/search/question/desc',
      );
      if (respQuestionMaps?.data?.map) {
        setListQuestionMaps(respQuestionMaps?.data?.map);
      }
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const fetchGameData = async () => {
    if (idGame) {
      try {
        setLoading(true);
        const resp = await axiosInstance.get(`/sc/game/${idGame}`);
        if (resp?.data) {
          const gameRes = resp?.data;
          setGame({
            name: gameRes.name,
            lifecycle: gameRes.lifecycle,
          });
          setTimingUrl(gameRes.timings_guid);
          setQuestionUrl(gameRes.questions_guid);
          setMapUrl(gameRes.level_map_guid);
          setIconName(gameRes.icon);
          setIconColor(gameRes.icon_color);
          setQuestionsMap(gameRes.questions_map_guid);
        }
        setLoading(false);
      } catch {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    return () => {
      window.removeEventListener('resize', handleResizeEditor, true);
    };
  }, []);

  useEffect(() => {
    if (axiosInstance) {
      fetchEssentialData();
      fetchGameData();
    }
    window.addEventListener('resize', handleResizeEditor, true);
  }, [axiosInstance]);

  return (
    <PageHeader
      guid={idGame}
      defaultName={game?.name}
      icon={iconName}
      iconColor={iconColor}
      editor={
        <div>
          <div className="row game-content-group">
            <div className="col-md-6">
              <p>Select Level Map: </p>
              <Select
                id="map"
                variant="outlined"
                fullWidth
                onChange={onMapChange}
                value={mapUrl}
                displayEmpty
                disabled={isDisableSelect}
                className="game-select-item"
              >
                <MenuItem value="">None</MenuItem>
                {listMaps.map(map => (
                  <MenuItem value={map.guid} key={map.guid}>
                    {map.name}
                  </MenuItem>
                ))}
              </Select>
              <br />
            </div>
            <div className="col-md-6">
              <p>Select Questions Map: </p>
              <Select
                id="question"
                variant="outlined"
                fullWidth
                onChange={onChangeQuestionsMap}
                value={questionsMap}
                displayEmpty
                disabled={isDisableSelect}
                className="game-select-item"
              >
                <MenuItem value="">None</MenuItem>
                {listQuestionMaps.map(map => (
                  <MenuItem value={map.guid} key={map.guid}>
                    {map.name}
                  </MenuItem>
                ))}
              </Select>
              <br />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <p>Select Timings: </p>
              <Select
                id="timing"
                variant="outlined"
                fullWidth
                onChange={onTimingChange}
                value={timingUrl}
                displayEmpty
                disabled={isDisableSelect}
                className="game-select-item"
              >
                <MenuItem value="">None</MenuItem>
                {listTimings.map(timing => (
                  <MenuItem value={timing.guid} key={timing.guid}>
                    {timing.name}
                  </MenuItem>
                ))}
              </Select>
              <br />
            </div>
            <div className="col-md-6">
              <p>Select Questions: </p>
              <Select
                id="question"
                variant="outlined"
                fullWidth
                onChange={onQuestionChange}
                value={questionUrl}
                displayEmpty
                disabled={isDisableSelect}
                className="game-select-item"
              >
                <MenuItem value="">None</MenuItem>
                {listQuestions.map(question => (
                  <MenuItem value={question.guid} key={question.guid}>
                    {question.name}
                  </MenuItem>
                ))}
              </Select>
              <br />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-12">
              <br />
              <p>Icon Color: </p>
              <ChromePicker
                color={iconColor}
                onChangeComplete={onChangeIconColor}
                width="100%"
              />
              <br />
            </div>
            <div className="col-lg-9 col-md-12">
              <br />
              <p>Icon Name: </p>
              <div className="icon-container">{iconList}</div>
            </div>
          </div>
        </div>
      }
      defaultTab={ButtonType.EDITOR}
      showLifecycle
      defaultLifecycle={game?.lifecycle}
      isLoading={loading}
      onSave={onSaveGame}
      isDisableSave={!(mapUrl && questionsMap && questionUrl && timingUrl)}
      onCancel={() => {
        history.push('/games', {
          previewGame: id,
        });
      }}
      onChangeLifecycle={value =>
        setGame(prev => ({ ...prev, lifecycle: value }))
      }
    />
  );
};
