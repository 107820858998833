import path from 'path';
import * as tmx from 'node-tmx-parser';
import * as PIXI from 'pixi.js';
import { formatImageSource } from 'utils/format';

export default function tileMapLoader(loader, resource) {
  return new Promise((res, rej) => {
    let newResource = {
      ...resource,
    };
    if (typeof resource.data === 'string') {
      newResource = {
        ...newResource,
        data: new DOMParser().parseFromString(resource.data, 'application/xml')
          .children[0],
        type: PIXI.LoaderResource.TYPE.XML,
      };
    }

    if (
      !newResource.data ||
      newResource.type !== PIXI.LoaderResource.TYPE.XML ||
      !newResource.data.children[0].getElementsByTagName('tileset')
    ) {
      rej();
    }

    const route = path.dirname(newResource.url.replace(loader.baseUrl, ''));

    const loadOptions = {
      crossOrigin: newResource.crossOrigin,
      parentResource: newResource,
    };

    tmx.parse(
      newResource.xhr.responseText,
      route,
      (err, map) => {
        if (err) {
          rej(err);
        }

        map.tileSets.forEach(tileset => {
          let imageSourceUrl = tileset.image.source;
          imageSourceUrl = formatImageSource(tileset.image.source);
          if (!(imageSourceUrl in loader.resources)) {
            loader.add(imageSourceUrl, imageSourceUrl, loadOptions);
          }
        });
        newResource.route = route;
        newResource.data = map;

        res(newResource);
      },
      false,
      { trim: true },
    );
  });
}
