import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useKeycloak } from '@react-keycloak/web';

// eslint-disable-next-line react/prop-types
export function PrivateRoute({ component: Component, ...rest }) {
  const [keycloak] = useKeycloak();

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={props =>
        keycloak.authenticated ? (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              // eslint-disable-next-line react/prop-types
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
