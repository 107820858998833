import React, { useState } from 'react';
import queryString from 'query-string';
import { Tab, Tabs } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { TAB_NAME } from 'utils/constants';
import UpdateSequenceDesign from './UpdateSequenceDesign/UpdateSequenceDesign';
import UpdateSequenceDescription from './UpdateSequenceDescription/UpdateSequenceDescription';
import SequenceOverview from './SequenceOverview/SequenceOverview';
import './UpdateSequence.scss';

export default () => {
  const { id, tab = id ? TAB_NAME.OVERVIEW : TAB_NAME.DESIGN } = useParams();
  const [tabName, setTabName] = useState(tab);

  const onChangeTab = (_, value) => {
    setTabName(value);
    const newUrl = queryString.stringifyUrl({
      url: window.location.pathname,
      query: {
        tab: value,
      },
    });
    window.history.replaceState(null, '', newUrl);
  };
  return (
    <div className="update-sequence-container">
      <div className="update-sequence-tab-container">
        <Tabs
          value={tabName}
          onChange={onChangeTab}
          aria-label="basic tabs example"
        >
          {id && <Tab label="Overview" value={TAB_NAME.OVERVIEW} />}
          <Tab label="Design" value={TAB_NAME.DESIGN} />
          {id && <Tab label="Description" value={TAB_NAME.DESCRIPTION} />}
        </Tabs>
      </div>
      {tabName === TAB_NAME.OVERVIEW && <SequenceOverview id={id} />}
      {tabName === TAB_NAME.DESIGN && (
        <UpdateSequenceDesign id={id} setTabName={setTabName} />
      )}
      {tabName === TAB_NAME.DESCRIPTION && (
        <UpdateSequenceDescription id={id} />
      )}
    </div>
  );
};
