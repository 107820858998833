import * as PIXI from 'pixi.js';
import { formatImageSource } from 'utils/format';

export default class TileSet {
  constructor(route, tileSet) {
    Object.assign(this, tileSet);
    // this.baseTexture = PIXI.Texture.from(this.image.source, false, SCALE_MODES.NEAREST);
    this.baseTexture = PIXI.Texture.from(formatImageSource(this.image.source), {
      scaleMode: PIXI.SCALE_MODES.LINEAR,
    });
    this.textures = [];
    for (
      let y = this.margin;
      y < Math.floor(this.image.height / this.tileHeight) * this.tileHeight;
      y += this.tileHeight + this.spacing
    ) {
      for (
        let x = this.margin;
        x < Math.floor(this.image.width / this.tileWidth) * this.tileWidth;
        x += this.tileWidth + this.spacing
      ) {
        this.textures.push(
          new PIXI.Texture(
            this.baseTexture,
            new PIXI.Rectangle(x, y, this.tileWidth, this.tileHeight),
          ),
        );
      }
    }
  }
}
