import React, { useCallback, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAxios } from 'utils/hooks';
import { NEUX_URL } from 'utils/constants';
import { Select, MenuItem } from '@material-ui/core';
import PageHeader, { ButtonType } from 'components/PageHeader';

import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-dracula';
import './UpdateMap.scss';

export default () => {
  const { id } = useParams();
  const history = useHistory();

  const [height, setHeight] = useState(
    document?.body?.getBoundingClientRect()?.height,
  );
  const [loading, setLoading] = useState(false);
  const [mapContent, setMapContent] = useState('');
  const [map, setMap] = useState({
    name: '',
    lifecycle: '',
  });
  const [mapType, setMapType] = useState('level');
  const [idMap, setIdMap] = React.useState(id);

  const axiosInstance = useAxios(NEUX_URL);

  const onChangeContent = content => {
    setMapContent(content);
  };

  const onMapTypeChange = event => {
    setMapType(event.target.value);
  };

  const handleResizeEditor = () => {
    if (document?.body?.getBoundingClientRect()?.height !== height) {
      setHeight(document?.body?.getBoundingClientRect()?.height);
    }
  };

  const onSaveMap = useCallback(
    async values => {
      try {
        setLoading(true);
        if (idMap) {
          await axiosInstance.post('sc/map/', {
            data: btoa(mapContent),
            guid: idMap,
            type: mapType,
            ...values,
          });
        } else {
          const { data } = await axiosInstance.put('sc/map', {
            data: btoa(mapContent),
            type: mapType,
            ...values,
          });
          setIdMap(data.guid);
          window.history.replaceState(null, '', `/map/${data.guid}`);
        }
        setLoading(false);
        toast.success('Saved');
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [mapType, mapContent, idMap, axiosInstance],
  );

  const fetchMapData = async () => {
    try {
      setLoading(true);
      const resp = await axiosInstance.get(`sc/map/${id}`);
      if (resp && resp.data) {
        setMapContent(atob(resp.data.data));
        setMap({
          name: resp.data.name,
          lifecycle: resp.data.lifecycle,
        });
        setMapType(resp.data.type);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    return () => {
      window.removeEventListener('resize', handleResizeEditor, true);
    };
  }, []);

  useEffect(() => {
    if (id && axiosInstance) {
      fetchMapData();
    }
    window.addEventListener('resize', handleResizeEditor, true);
  }, [axiosInstance]);

  return (
    <PageHeader
      guid={idMap}
      defaultName={map?.name}
      editor={
        <div>
          <div className="map-content-group">
            <p>Type: </p>
            <Select
              label="Type"
              id="map"
              variant="outlined"
              fullWidth
              onChange={onMapTypeChange}
              value={mapType}
            >
              <MenuItem value="welcome">Welcome</MenuItem>
              <MenuItem value="level">Level</MenuItem>
              <MenuItem value="question">Question</MenuItem>
              <MenuItem value="finish">Finish</MenuItem>
            </Select>
            <p />
          </div>
          <div className="row map-content-group">
            <div className="col-lg-12">
              <AceEditor
                mode="json"
                theme="dracula"
                onChange={onChangeContent}
                name="map-content"
                width="100%"
                height={`${height - 180}px`}
                fontSize={12}
                splits={1}
                orientation="below"
                value={mapContent}
                readOnly={loading}
                showPrintMargin={false}
                setOptions={{ useWorker: false }}
              />
            </div>
          </div>
        </div>
      }
      defaultTab={ButtonType.EDITOR}
      showLifecycle
      defaultLifecycle={map?.lifecycle}
      isLoading={loading}
      onSave={onSaveMap}
      onCancel={() => {
        history.push('/maps', {
          previewMap: id,
        });
      }}
    />
  );
};
