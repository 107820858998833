import React, { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';

const LoginPage = () => {
  const [keycloak] = useKeycloak();

  useEffect(() => {
    keycloak.login();
  }, [keycloak]);
  return <div />;
};

export default LoginPage;
