import React, { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';

const RegistrationPage = () => {
  const [keycloak] = useKeycloak();

  useEffect(() => {
    keycloak.register();
  }, [keycloak]);
  return <div />;
};

export default RegistrationPage;
