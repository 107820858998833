import * as React from 'react';
import { DEG_TO_RAD, ObservablePoint } from 'pixi.js';
import { TmxObject } from 'node-tmx-parser';
import SpriteAnimated from './SpriteAnimated';
import { getTextures, FLIP, getFlip } from './utils';

function getTransform(horizontalFlip, verticalFlip, diagonalFlip, tile) {
  const anchor = { x: 0, y: 0 };
  const scale = { x: 1, y: 1 };
  let rotation = tile && tile.rotation ? (tile.rotation * Math.PI) / 180 : 0;

  if (horizontalFlip) {
    anchor.x = 1;
    scale.x = -1;
  }

  if (verticalFlip) {
    anchor.y = 1;
    scale.y = -1;
  }

  if (diagonalFlip) {
    if (horizontalFlip) {
      anchor.x = 0;
      scale.x = 1;
      anchor.y = 1;
      scale.y = 1;
      rotation = DEG_TO_RAD * 90;
    } else if (verticalFlip) {
      anchor.x = 1;
      scale.x = 1;
      anchor.y = 0;
      scale.y = 1;
      rotation = DEG_TO_RAD * -90;
    } else {
      rotation = DEG_TO_RAD * 180;
    }
  }
  // get flip in case of Object Layer
  if (tile) {
    const flip = getFlip(tile);
    switch (flip) {
      case FLIP.HORIZONTAL: {
        scale.x = -1;
        anchor.x = 1;
        break;
      }
      case FLIP.VERTICAL: {
        scale.y = -1;
        anchor.y = 0;
        break;
      }
      case FLIP.DIAGONALLY: {
        scale.x = -1;
        scale.y = -1;
        anchor.x = 1;
        anchor.y = 0;
        break;
      }
      default: {
        break;
      }
    }
  }

  return { anchor, scale, rotation };
}

export default ({
  tile,
  tileSet,
  horizontalFlip,
  verticalFlip,
  diagonalFlip,
  x,
  y,
  animationSpeed,
  isPlaying,
}) => {
  const { anchor, scale, rotation } = getTransform(
    horizontalFlip,
    verticalFlip,
    diagonalFlip,
    tile,
  );
  const textures = getTextures(tile, tileSet);

  const initialPosition = {
    x: parseInt(x, 10),
    // eslint-disable-next-line max-len
    y:
      y -
      (textures && textures.length && textures[0] ? textures[0].height : 0) -
      (tile instanceof TmxObject ? tile.height : 0),
  };

  return textures && textures.length && textures.every(texture => texture) ? (
    <SpriteAnimated
      textures={textures}
      anchor={new ObservablePoint(() => {}, null, anchor.x, anchor.y)}
      scale={scale}
      rotation={rotation}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...initialPosition}
      animationSpeed={animationSpeed}
      isPlaying={isPlaying}
    />
  ) : null;
};
