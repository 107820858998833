import React, { useState, useEffect, useMemo } from 'react';
import { Stage } from '@inlet/react-pixi';
import ToneTransport from 'services/ToneTransport';
import { QUESTION_TYPE } from 'utils/constants';
import { TileContext } from './TileContext';
import TiledMapContainer from './TiledMapContainer';

const Tiled = ({
  mapUrl,
  timingUrl,
  axiosInstance,
  tileText,
  trackSliderObject,
  currentQuestion,
}) => {
  const [animationTypes, setAnimationTypes] = useState(null);
  const [lastAnimationTypes, setLastAnimationTypes] = useState(null);

  useEffect(() => {
    let active = true;
    if (!timingUrl || timingUrl?.pathname) {
      setAnimationTypes(null);
    }
    if (axiosInstance && timingUrl?.pathname) {
      (async () => {
        try {
          const { data } = await axiosInstance.get(timingUrl);
          if (active) {
            setAnimationTypes(data); // make sure animationTypes is refresh
          }
        } catch (error) {
          /* eslint-disable-next-line no-console */
          console.log(error);
        }
      })();
    }
    return () => {
      active = false;
      setAnimationTypes(null);
    };
  }, [axiosInstance, timingUrl?.pathname]);

  useEffect(() => {
    ToneTransport.getInstance().clearEventEnding();
    if (animationTypes?.bpm) {
      ToneTransport.getInstance().setBpm(animationTypes?.bpm);
    }
    if (animationTypes?.slider?.length > 0) {
      setLastAnimationTypes(animationTypes);
      animationTypes?.slider.forEach(animation => {
        ToneTransport.getInstance().startAnimation(animation, animationTypes);
      });
    }
  }, [animationTypes]);

  const customizeQuestion = useMemo(() => {
    if (!currentQuestion || !lastAnimationTypes?.slider) return currentQuestion;
    const { type, nback, congruency } = currentQuestion;
    if (type === QUESTION_TYPE.NBACK) {
      if (congruency) {
        const sortedSliders = lastAnimationTypes?.slider?.sort(
          (a, b) =>
            ToneTransport.getInstance().getTimeSecond(a.posStart) -
            ToneTransport.getInstance().getTimeSecond(b.posStart),
        );
        const selectedTile = sortedSliders[sortedSliders?.length - nback];
        return {
          ...currentQuestion,
          tiles: [selectedTile.guid],
        };
      }
    }
    return currentQuestion;
  }, [currentQuestion, lastAnimationTypes]);

  const tileContextValue = useMemo(
    () => ({
      animationTypes,
      tileText,
      trackSliderObject,
      currentQuestion: customizeQuestion,
    }),
    [animationTypes, tileText, trackSliderObject, customizeQuestion],
  );

  return (
    <Stage tabIndex="0" width={0} height={0}>
      <TileContext.Provider value={tileContextValue}>
        <TiledMapContainer
          tiledPath={mapUrl}
          axiosInstance={axiosInstance}
          tileText={tileText}
        />
      </TileContext.Provider>
    </Stage>
  );
};

Tiled.defaultProps = {
  trackSliderObject: () => {},
};

export default Tiled;
