import React, { useCallback, useEffect, useState } from 'react';
import { useAxios } from 'utils/hooks';
import { Select, MenuItem, TextField } from '@material-ui/core';
import { NEUX_URL, SEQUENCE_LIFECYCLE } from 'utils/constants';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import EmptyImage from '@assets/images/icons/emptyImage.png';
import { toast } from 'react-toastify';
import { getSequenceImagePath } from 'utils/format';
import './UpdateSequenceDescription.scss';

const emptyEditorState = EditorState.createEmpty();

const UpdateSequenceDescription = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [imageIconFile, setImageIconFile] = useState('');
  const [imageHeaderFile, setImageHeaderFile] = useState('');
  const axiosInstance = useAxios(NEUX_URL);
  const [defaultSequence, setDefaultSequence] = useState(null);
  const [sequenceDescription, setSequenceDescription] = useState({
    imageIcon: '',
    imageHeader: '',
    bpm: '',
    lifeCycle: '',
    description: emptyEditorState,
  });

  const getEditorContentFromHTML = htmlText => {
    let editorState = emptyEditorState;
    if (htmlText) {
      const contentBlock = htmlToDraft(htmlText);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks,
        );
        editorState = EditorState.createWithContent(contentState);
      }
    }
    return editorState;
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      if (id) {
        const resp = await axiosInstance.get(`/sc/sequence/${id}`);
        if (resp?.data) {
          setDefaultSequence(resp?.data);
          setSequenceDescription({
            imageIcon: getSequenceImagePath(id, 'icon'),
            imageHeader: getSequenceImagePath(id, 'header'),
            bpm: resp?.data?.bpm,
            lifeCycle: resp?.data?.lifecycle,
            description: getEditorContentFromHTML(resp?.data?.description),
          });
        }
      }
    } catch (error) {
      console.log('error', error);
      toast.error(error?.response?.data?.message ?? 'Error');
    } finally {
      setLoading(false);
    }
  };

  const onChangeDescription = value => {
    setSequenceDescription(prev => ({
      ...prev,
      description: value,
    }));
  };

  const onChangeIcon = e => {
    e.preventDefault();
    const { files } = e.target;
    const imageLink = URL.createObjectURL(files[0]);
    setImageIconFile(files[0]);
    setSequenceDescription(prev => ({
      ...prev,
      imageIcon: imageLink,
    }));
    e.target.value = '';
  };

  const onChangeHeader = e => {
    e.preventDefault();
    const { files } = e.target;
    const imageLink = URL.createObjectURL(files[0]);
    setImageHeaderFile(files[0]);
    setSequenceDescription(prev => ({
      ...prev,
      imageHeader: imageLink,
    }));
    e.target.value = '';
  };

  const onChangeBpm = e => {
    setSequenceDescription(prev => ({
      ...prev,
      bpm: e?.target?.value,
    }));
  };

  const onChangeLifeCycle = e => {
    setSequenceDescription(prev => ({
      ...prev,
      lifeCycle: e?.target?.value,
    }));
  };

  const onSaveImage = useCallback(
    async (type, file) => {
      const formData = new FormData();
      formData.append('file', file);
      await axiosInstance.put(`/sc/sequence/${id}/img/${type}`, formData);
    },
    [id, axiosInstance],
  );

  const onSave = useCallback(async () => {
    const { description, bpm, lifeCycle } = sequenceDescription;
    try {
      setLoading(true);
      if (imageIconFile) {
        await onSaveImage('icon', imageIconFile);
      }
      if (imageHeaderFile) {
        await onSaveImage('header', imageHeaderFile);
      }
      const { games, ...restDefault } = defaultSequence;
      const newGames = games.map(({ guid }, index) => ({
        game_guid: guid,
        order: index,
      }));
      await axiosInstance.post(`/sc/sequence`, {
        ...restDefault,
        games: newGames,
        description: draftToHtml(convertToRaw(description.getCurrentContent())),
        bpm,
        lifecycle: lifeCycle,
      });
      toast.success('Saved');
    } catch (error) {
      toast.error(error?.response?.data?.message ?? 'Error');
    } finally {
      setLoading(false);
    }
  }, [
    axiosInstance,
    onSaveImage,
    sequenceDescription,
    defaultSequence,
    imageHeaderFile,
    imageIconFile,
  ]);

  useEffect(() => {
    if (axiosInstance) {
      fetchData();
    }
  }, [axiosInstance]);

  useEffect(
    () => () => {
      if (imageIconFile) {
        URL.revokeObjectURL(imageIconFile);
      }
    },
    [imageIconFile],
  );

  useEffect(
    () => () => {
      if (imageHeaderFile) {
        URL.revokeObjectURL(imageHeaderFile);
      }
    },
    [imageHeaderFile],
  );

  const {
    imageHeader,
    imageIcon,
    description,
    bpm,
    lifeCycle,
  } = sequenceDescription;

  return (
    <div className="update-sequence-description">
      <div className="row">
        <div className="col-md-6 sequence-description-form-group">
          <div className="sd-form-header">
            <span>Icon</span>
            <div className="btn upload-file-btn">
              <button
                disabled={loading}
                type="button"
                className="btn btn-primary"
              >
                Upload Icon
              </button>
              <input
                type="file"
                accept=".png,.PNG"
                id="file"
                onChange={onChangeIcon}
              />
            </div>
          </div>
          <div className="sd-form-preview-image">
            <img
              src={imageIcon}
              onError={e => {
                e.target.onerror = null;
                e.target.src = EmptyImage;
              }}
            />
          </div>
          <br />
        </div>
        <div className="col-md-6 sequence-description-form-group">
          <div className="sd-form-header">
            <span>Header</span>
            <div className="btn upload-file-btn">
              <button
                disabled={loading}
                type="button"
                className="btn btn-primary"
              >
                Upload Header
              </button>
              <input
                type="file"
                accept=".png,.PNG"
                id="file"
                onChange={onChangeHeader}
              />
            </div>
          </div>
          <div className="sd-form-preview-image">
            <img
              src={imageHeader}
              onError={e => {
                e.target.onerror = null;
                e.target.src = EmptyImage;
              }}
            />
          </div>
          <br />
        </div>
        <div className="col-md-6 sequence-description-form-group">
          <div className="sd-form-header">
            <span>BPM </span>
          </div>
          <TextField
            fullWidth
            variant="outlined"
            type="number"
            placeholder="Enter BPM"
            aria-label="bpm"
            onChange={onChangeBpm}
            value={bpm}
            disabled={loading}
          />
          <br />
        </div>
        <div className="col-md-6 sequence-description-form-group">
          <div className="sd-form-header">
            <span>LifeCycle </span>
          </div>
          <Select
            variant="outlined"
            fullWidth
            onChange={onChangeLifeCycle}
            value={lifeCycle}
            displayEmpty
            disabled={loading}
          >
            {SEQUENCE_LIFECYCLE.map(item => (
              <MenuItem value={item} key={item}>
                {item.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
          <br />
        </div>
        <div className="col-md-12 sequence-description-form-group">
          <div className="sd-form-header">
            <span>Description</span>
          </div>
          <Editor
            editorState={description}
            onEditorStateChange={onChangeDescription}
            toolbar={{
              inline: { inDropdown: true },
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
              history: { inDropdown: true },
            }}
          />
        </div>
      </div>
      <div className="sequence-description-save-button">
        <button
          disabled={loading}
          type="button"
          className="btn btn-primary"
          onClick={onSave}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default UpdateSequenceDescription;
