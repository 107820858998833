import React, { useCallback, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAxios } from 'utils/hooks';
import { NEUX_URL } from 'utils/constants';
import PageHeader, { ButtonType } from 'components/PageHeader';

import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-dracula';
import './UpdateTiming.scss';

export default () => {
  const { id } = useParams();
  const history = useHistory();

  const [height, setHeight] = React.useState(
    document?.body?.getBoundingClientRect()?.height,
  );
  const [loading, setLoading] = React.useState(false);
  const [timingContent, setTimingContent] = React.useState('');
  const [timing, setTiming] = React.useState({
    name: '',
    lifecycle: '',
  });
  const axiosInstance = useAxios(NEUX_URL);
  const [idTiming, setIdTiming] = React.useState(id);

  const onChangeContent = content => {
    setTimingContent(content);
  };

  const handleResizeEditor = () => {
    if (document?.body?.getBoundingClientRect()?.height !== height) {
      setHeight(document?.body?.getBoundingClientRect()?.height);
    }
  };

  const onSaveTiming = useCallback(
    async values => {
      try {
        setLoading(true);
        const params = {
          data: btoa(timingContent),
          ...values,
        };

        if (idTiming) {
          params.guid = idTiming;
          await axiosInstance.post('sc/timings', params);
        } else {
          const { data } = await axiosInstance.put('sc/timings', params);
          setIdTiming(data.guid);
          window.history.replaceState(null, '', `/timing/${data.guid}`);
        }
        setTiming({
          name: values?.name,
          lifecycle: values?.lifecycle,
        });
        setLoading(false);
        toast.success('Saved');
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [timingContent, axiosInstance, idTiming],
  );

  const fetchTimingData = async () => {
    try {
      setLoading(true);
      const resp = await axiosInstance.get(`sc/timings/${id}`);
      if (resp && resp.data) {
        setTimingContent(atob(resp.data.data));
        setTiming({
          name: resp?.data?.name,
          lifecycle: resp?.data?.lifecycle,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      window.removeEventListener('resize', handleResizeEditor, true);
    };
  }, []);

  useEffect(() => {
    if (id && axiosInstance) {
      fetchTimingData();
    }
    window.addEventListener('resize', handleResizeEditor, true);
  }, [axiosInstance]);

  return (
    <PageHeader
      guid={idTiming}
      defaultName={timing?.name}
      editor={
        <AceEditor
          mode="json"
          theme="dracula"
          onChange={onChangeContent}
          name="timing-content"
          width="100%"
          height={`${height - 180}px`}
          fontSize={12}
          splits={1}
          orientation="below"
          value={timingContent}
          readOnly={loading}
          showPrintMargin={false}
          setOptions={{ useWorker: false }}
        />
      }
      defaultTab={ButtonType.EDITOR}
      showLifecycle
      defaultLifecycle={timing?.lifecycle}
      isLoading={loading}
      onSave={onSaveTiming}
      onCancel={() => {
        history.push('/timings', {
          previewTiming: id,
        });
      }}
    />
  );
};
