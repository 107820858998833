import * as Tone from 'tone';
import { posToString } from 'utils/format';

class ToneTransport {
  static instance = null;

  static getInstance = () => {
    if (ToneTransport.instance == null) {
      ToneTransport.instance = new ToneTransport();
      this.instance.emitter = new Tone.Emitter();
    }
    return this.instance;
  };

  setBpm = bpm => {
    Tone.Transport.bpm.value = bpm;
  };

  startTimer = callback => {
    Tone.Transport.position = '0:0:0';

    const timer = new Tone.Loop(() => {
      callback(time => {
        const total =
          Tone.Time(time).toMilliseconds() + Tone.Time('16n').toMilliseconds();
        return Tone.Time(total / 1000).toBarsBeatsSixteenths();
      });
    }, '16n').start(0);
    Tone.context.resume().then(() => Tone.Transport.start());
    return timer;
  };

  clearTimer = timer => {
    timer?.stop();
    timer?.cancel();
    timer?.dispose();
    Tone.Transport?.stop();
    Tone.Transport?.cancel();
  };

  clearEventEnding = () => {
    Tone.Transport.clear();
    Tone.Transport.position = '0:0:0';
  };

  getTime = time => Tone.Time(time / 1000).toBarsBeatsSixteenths();

  checkToneStatus = checkStatusCallback => {
    Tone.context.resume().then(() => {
      Tone.Transport.start();
      if (typeof checkStatusCallback === 'function') {
        checkStatusCallback();
      }
      Tone.Transport.stop();
    });
  };

  startEventEnding = (data, callback) => {
    if (data.bpm) {
      Tone.Transport.bpm.value = data.bpm;
    }
    Tone.Transport.timeSignature = [4, 4];
    Tone.Transport.loopStart = '0:0:0';
    Tone.Transport.loopEnd = data.length;
    // schedule when end game

    const id = Tone.Transport.scheduleOnce(() => {
      callback();
      Tone.Transport.stop();
      Tone.Transport.cancel();
    }, data.length);
    Tone.context.resume().then(() => Tone.Transport.start());
    return id;
  };

  clearSchedule = id => {
    Tone.Transport.clear(id);
    Tone.Transport.cancel();
  };

  startAnimation = (animation, animationTypes) => {
    const {
      motionDurationMs,
      motionDurationPos,
      motionDistanceInTiles,
    } = animationTypes;
    const motionDuration = motionDurationPos
      ? Tone.Time(motionDurationPos).toMilliseconds()
      : motionDurationMs;
    const directionAngle = (animation.direction * Math.PI) / 180;
    const animationGuid = animation?.guid?.replaceAll('-', '_');
    // set start animation event
    Tone.Transport.scheduleOnce(() => {
      ToneTransport.getInstance().emitter.emit(
        `START_BEGIN_${animationGuid}_${posToString(animation.posStart)}`,
        {
          directionAngle,
          tileDirection: animation.direction,
          motionDuration,
          motionDistanceInTiles,
          isIncrease: true,
        },
      );
    }, animation.posStart);

    const startFinishTime = Tone.Time(
      (Tone.Time(animation.posStart).toMilliseconds() + motionDuration) / 1000,
    ).toBarsBeatsSixteenths();
    Tone.Transport.scheduleOnce(() => {
      ToneTransport.getInstance().emitter.emit(
        `START_FINISH_${animationGuid}_${posToString(animation.posStart)}`,
        {
          motionDistanceInTiles,
          directionAngle,
          tileDirection: animation.direction,
        },
      );
    }, startFinishTime);

    const endFinishTime = Tone.Time(
      (Tone.Time(animation.posEnd).toMilliseconds() - motionDuration) / 1000,
    ).toBarsBeatsSixteenths();
    Tone.Transport.scheduleOnce(() => {
      ToneTransport.getInstance().emitter.emit(
        `END_BEGIN_${animationGuid}_${posToString(animation.posEnd)}`,
        {
          directionAngle,
          motionDuration,
          motionDistanceInTiles,
          isIncrease: false,
          tileDirection: animation.direction,
        },
      );
    }, endFinishTime);

    Tone.Transport.scheduleOnce(() => {
      ToneTransport.getInstance().emitter.emit(
        `END_FINISH_${animationGuid}_${posToString(animation.posEnd)}`,
        { tileDirection: animation.direction },
      );
    }, animation.posEnd);
  };

  clearEvent = name => {
    ToneTransport.getInstance().emitter.off(name);
  };

  getTime = time => {
    return Tone.Time(time / 1000).toBarsBeatsSixteenths();
  };

  getTimeSecond = time => {
    return Tone.Time(time).toMilliseconds();
  };
}

export default ToneTransport;
