export const NEUX_URL = 'https://api.neuxscience.com';

export const METRIC_EVENT_BATCH = {
  MC_SEQUENCE: 'metric.sc.sequence',
};

export const METRIC_EVENT_TYPE = {
  WELCOME_MAP_STARTED: 'welcome_map_started',
  WELCOME_MAP_ENDED: 'welcome_map_ended',
  LEVEL_MAP_STARTED: 'level_map_started',
  LEVEL_MAP_ENDED: 'level_map_ended',
  QUESTION_MAP_STARTED: 'question_map_started',
  QUESTION_MAP_ENDED: 'question_map_ended',
  QUESTION_ASKED: 'question_asked',
  QUESTION_ANSWERED: 'question_answered',
  FINISH_MAP_STARTED: 'finish_map_started',
  FINISH_MAP_ENDED: 'finish_map_ended',
  SLIDER_POS_START_BEGIN: 'slider_pos_start_begin',
  SLIDER_POS_START_FINISH: 'slider_pos_start_finish',
  SLIDER_POS_END_BEGIN: 'slider_pos_end_begin',
  SLIDER_POS_END_FINISH: 'slider_pos_end_finish',
};

export const GAME_MAP_TYPE = {
  WELCOME: 'welcome',
  QUESTION: 'question',
  LEVEL: 'level',
  FINISH: 'finish',
  QA: 'question_answer',
  /** Slider object */
  SLIDER_POS_START_BEGIN: 'slider_pos_start_begin',
  SLIDER_POS_START_FINISH: 'slider_pos_start_finish',
  SLIDER_POS_END_BEGIN: 'slider_pos_end_begin',
  SLIDER_POS_END_FINISH: 'slider_pos_end_finish',
};

export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 50;
export const PAGE_SIZE_OPTIONS = [50];

export const TAB_NAME = {
  OVERVIEW: 'overview',
  DESIGN: 'design',
  DESCRIPTION: 'description',
};

export const SEQUENCE_LIFECYCLE_VALUES = {
  DEBUG: 'debug',
  WIP: 'wip',
  ARCHIVED: 'archived',
  PUBLISHED: 'published',
};
export const SEQUENCE_LIFECYCLE = [
  SEQUENCE_LIFECYCLE_VALUES.DEBUG,
  SEQUENCE_LIFECYCLE_VALUES.WIP,
  SEQUENCE_LIFECYCLE_VALUES.ARCHIVED,
  SEQUENCE_LIFECYCLE_VALUES.PUBLISHED,
];

export const QUESTION_TYPE = {
  TILE_LIST: 'tilelist',
  NBACK: 'nback',
};

export const TILE_TYPE = {
  SLIDER: 'slider',
  CHOICE: 'choice',
  HINT: 'hint',
  KEYBOARD_MAPPING: 'keyboardmapping',
  NBACK_CHOICE: 'nback-choice',
};

export const LAYER_TYPE = {
  OBJECT: 'object',
};

export const SEARCHABLE_TABLE_LIFECYCLE = {
  WIP: SEQUENCE_LIFECYCLE_VALUES.WIP,
  DEBUG: SEQUENCE_LIFECYCLE_VALUES.DEBUG,
  PUBLISHED: SEQUENCE_LIFECYCLE_VALUES.PUBLISHED,
  ARCHIVED: SEQUENCE_LIFECYCLE_VALUES.ARCHIVED,
};
