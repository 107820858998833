import React, {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useMemo,
} from 'react';
import ToneTransport from 'services/ToneTransport';
import { formatTimeWithSecond } from 'utils/format';
import './TimeTracker.scss';

const TimeTracker = forwardRef(({ showStopWatch, bpm }, ref) => {
  const [clockTimer, setClockTimer] = useState({
    sequence: null,
    game: null,
    question: null,
    position: null,
  });

  const [clockValues, setClockValues] = useState({
    sequence: 0,
    game: 0,
    question: 0,
    position: '0:0:0',
  });

  const [singleDate, setSingleDate] = useState({
    sequence: null,
    level: null,
    question: null,
    questionAnswer: null,
  });

  const setSequenceClock = useCallback(
    _sequenceClock => {
      if (_sequenceClock) {
        const clock = setInterval(() => {
          setClockValues(value => ({
            ...value,
            sequence: value.sequence + 0.1,
          }));
        }, 100);
        setClockTimer(prevValues => ({
          ...prevValues,
          sequence: clock,
        }));
      } else {
        clearInterval(clockTimer.sequence);
      }
    },
    [clockTimer.sequence],
  );

  const setGameClock = useCallback(
    _gameClock => {
      if (_gameClock) {
        const clock = setInterval(() => {
          setClockValues(value => ({
            ...value,
            game: value.game + 0.1,
          }));
        }, 100);
        setClockTimer(prevValues => ({
          ...prevValues,
          game: clock,
        }));
      } else {
        clearInterval(clockTimer.game);
      }
    },
    [clockTimer.game],
  );

  const setQuestionClock = useCallback(
    _questionClock => {
      if (_questionClock) {
        const clock = setInterval(() => {
          setClockValues(value => ({
            ...value,
            question: value.question + 0.1,
          }));
        }, 100);
        setClockTimer(prevValues => ({
          ...prevValues,
          question: clock,
        }));
      } else {
        clearInterval(clockTimer.question);
      }
    },
    [clockTimer.question],
  );

  const togglePositionClock = useCallback(async () => {
    if (clockTimer.position) {
      ToneTransport.getInstance().clearTimer(clockTimer.position);
    }
    await setClockValues(prevValues => ({
      ...prevValues,
      position: '0:0:0',
    }));
    const timer = ToneTransport.getInstance().startTimer(fn => {
      setClockValues(prevValues => {
        const time = fn(prevValues.position);
        return {
          ...prevValues,
          position: time,
        };
      });
    });
    setClockTimer(prevValues => ({
      ...prevValues,
      position: timer,
    }));
  }, [clockTimer.position]);

  const toggleQuestionAnswer = useCallback(() => {
    if (!singleDate.questionAnswer) {
      setSingleDate(value => ({
        ...value,
        questionAnswer: new Date(),
      }));
    } else {
      setSingleDate(value => ({
        ...value,
        questionAnswer: 0,
      }));
    }
  }, [singleDate.questionAnswer]);

  const toggleQuestion = useCallback(() => {
    if (!singleDate.question) {
      setSingleDate(value => ({
        ...value,
        question: new Date(),
      }));
    } else {
      setSingleDate(value => ({
        ...value,
        question: 0,
      }));
    }
  }, [singleDate.question]);

  const toggleLevel = useCallback(() => {
    if (!singleDate.level) {
      setSingleDate(value => ({
        ...value,
        level: new Date(),
      }));
    } else {
      setSingleDate(value => ({
        ...value,
        level: 0,
      }));
    }
  }, [singleDate.level]);

  const toggleSequence = useCallback(() => {
    if (!singleDate.sequence) {
      setSingleDate(value => ({
        ...value,
        sequence: new Date(),
      }));
    } else {
      setSingleDate(value => ({
        ...value,
        sequence: 0,
      }));
    }
  }, [singleDate.sequence]);

  const clearClockValue = () => {
    setClockValues({
      sequence: 0,
      game: 0,
      question: 0,
      position: '0:0:0',
    });
    setSingleDate({
      level: null,
      question: null,
      questionAnswer: null,
      sequence: null,
    });
  };

  useEffect(() => {
    return () => {
      clearInterval(clockTimer.sequence);
      clearInterval(clockTimer.game);
      clearInterval(clockTimer.question);
      if (clockTimer.position) {
        ToneTransport.getInstance().clearTimer(clockTimer.position);
      }
    };
  }, []);

  useEffect(() => {
    if (bpm) {
      ToneTransport.getInstance().setBpm(bpm);
    }
  }, [bpm]);
  /* eslint-disable-next-line no-param-reassign */
  ref.current = useMemo(
    () => ({
      setGameClock,
      setQuestionClock,
      setSequenceClock,
      togglePositionClock,
      clearClockValue,
      toggleSequence,
      toggleLevel,
      toggleQuestion,
      toggleQuestionAnswer,
      singleDate,
      clockValues,
    }),
    [
      setGameClock,
      setQuestionClock,
      togglePositionClock,
      togglePositionClock,
      clearClockValue,
      toggleSequence,
      toggleLevel,
      toggleQuestion,
      toggleQuestionAnswer,
      singleDate,
      clockValues,
    ],
  );

  if (showStopWatch)
    return (
      <div className="time-tracker-container">
        <div className="time-tracker-sequence">
          Sequence: {formatTimeWithSecond(clockValues.sequence)}
        </div>
        <div className="time-tracker-game">
          Game: {formatTimeWithSecond(clockValues.game)}
        </div>
        <div className="time-tracker-question">
          Question: {formatTimeWithSecond(clockValues.question)}
        </div>
        <div className="time-tracker-question">
          Position: {clockValues.position}
        </div>
      </div>
    );
  return null;
});

export default TimeTracker;
