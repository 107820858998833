import * as PIXI from 'pixi.js';
import tileMapLoader from 'tiled/tiledMapLoader';
import { formatMapUrlWithNeux } from 'utils/format';

class TileCaching {
  constructor() {
    if (TileCaching.instance) {
      return TileCaching.instance;
    }
    // Initialize your caching logic here
    this.cache = new Map();
    this.loader = new PIXI.Loader();
    this.initilize = false;
    TileCaching.instance = this;
  }

  // Add methods for caching operations here
  addToCache(key, value) {
    this.cache.set(key, value);
  }

  getFromCache(key) {
    return this.cache.get(key);
  }

  removeFromCache(key) {
    this.cache.delete(key);
  }

  getInitilize() {
    return this.initilize;
  }

  onLoadMap(map) {
    return new Promise(resolve => {
      this.loader.reset();
      this.loader
        .add(map)
        .load((loader, { [map]: resource }) => {
          this.loader.reset();
          if (resource) {
            tileMapLoader(loader, resource).then(resourceData => {
              this.addToCache(map, resourceData);
            });
          }
        })
        .onComplete.add(() => {
          resolve(true);
        });
    });
  }

  async setCachesFromSequence(sequence) {
    this.initilize = true;
    if (sequence?.result?.length === 0) return;
    const { result } = sequence;
    if (result?.length === 0) return;
    this.cache.clear();

    for (let index = 0; index < result.length; index += 1) {
      const game = result[index];
      const map =
        game?.welcome_map_guid || game?.level_map_guid || game?.finish_map_guid;
      // eslint-disable-next-line
      await this.onLoadMap(formatMapUrlWithNeux(map).toString());
    }
    this.loader.reset();
  }

  async setCachesFromGame(game) {
    this.initilize = true;
    this.cache.clear();
    const result = [game?.level_map_guid, game?.questions_map_guid];
    for (let index = 0; index < result.length; index += 1) {
      const map = result[index];
      // eslint-disable-next-line
      await this.onLoadMap(formatMapUrlWithNeux(map).toString());
    }
    this.loader.reset();
  }
}

const instance = new TileCaching();

export default instance;
