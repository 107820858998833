import React from 'react';
import './SequenceItem.scss';
import { Link } from 'react-router-dom';
import EmptyImage from '@assets/images/icons/emptyImage.png';
import { getSequenceImagePath } from 'utils/format';
import ReactTooltip from 'react-tooltip';
import { AiOutlineNumber } from 'react-icons/ai';

const SequenceItem = ({ sequence }) => {
  const imagePath = getSequenceImagePath(sequence?.guid, 'icon');
  return (
    <Link to={`/sequence/info/${sequence?.guid}`}>
      <div className="sequence-item-container">
        <div className="sequence-item-image">
          <img
            src={imagePath}
            onError={e => {
              e.target.onerror = null;
              e.target.src = EmptyImage;
            }}
            alt="logo"
          />
        </div>
        <div className="sequence-item-content">
          <div className="sequence-item-name">{sequence?.name}</div>
          <div className="sequence-item-more-information">
            <ReactTooltip id="game-count">
              <span>There are {sequence?.game_count} game(s) in sequence</span>
            </ReactTooltip>
            <div
              className="sequence-item-game-count"
              data-tip
              data-for="game-count"
            >
              <AiOutlineNumber /> {sequence?.game_count}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default SequenceItem;
