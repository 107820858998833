/* eslint-disable no-bitwise */
import React, { useState, useEffect } from 'react';
import { ObservablePoint } from 'pixi.js';
import { TmxObject } from 'node-tmx-parser';
import { useTick } from '@inlet/react-pixi';
import * as PIXI from 'pixi.js';
import SpriteAnimated from 'tiled/layers/SpriteAnimated';
import { getTileContext } from 'tiled/TileContext';
import { posToString } from 'utils/format';
import ToneTransport from 'services/ToneTransport';
import { FLIP, getFlip, getTextures } from 'tiled/layers/utils';

function getTransform(tile) {
  const anchor = { x: 0, y: 1 };
  const scale = { x: 1, y: 1 };
  const rotation = tile && tile.rotation ? (tile.rotation * Math.PI) / 180 : 0;

  // get flip in case of Object Layer
  if (tile) {
    const flip = getFlip(tile);
    switch (flip) {
      case FLIP.HORIZONTAL: {
        scale.x = -1;
        anchor.x = 1;
        break;
      }
      case FLIP.VERTICAL: {
        scale.y = -1;
        anchor.y = 0;
        break;
      }
      case FLIP.DIAGONALLY: {
        scale.x = -1;
        scale.y = -1;
        anchor.x = 1;
        anchor.y = 0;
        break;
      }
      default: {
        break;
      }
    }
  }

  return { anchor, scale, rotation };
}

export default ({ tile, tileSet, x, y, animationSpeed, isPlaying }) => {
  const { trackSliderObject, animationTypes } = getTileContext();
  const { anchor, scale, rotation } = getTransform(tile);
  const textures = getTextures(tile, tileSet);
  const positionReducer = (_, { data }) => data;
  const initialPosition = {
    x: parseInt(x, 10),
    // eslint-disable-next-line max-len
    y:
      y -
      (textures && textures.length && textures[0] ? textures[0].height : 0) -
      (tile instanceof TmxObject ? tile.height : 0),
  };
  const [position, updatePosition] = React.useReducer(
    positionReducer,
    initialPosition,
  );

  const [isAnimating, setIsAnimating] = useState(false);
  const [animationMeta, setAnimationMeta] = React.useState({});

  if (tile && tile instanceof TmxObject) {
    useEffect(() => {
      const animations = animationTypes?.slider.filter(
        type => type.guid === tile.name,
      );
      if (!animations || animations?.length === 0) return;
      animations.forEach(animation => {
        const animationGuid = animation?.guid?.replaceAll('-', '_'); // fix bug tone emitter
        ToneTransport.getInstance().emitter.once(
          `START_BEGIN_${animationGuid}_${posToString(animation.posStart)}`,
          ({ tileDirection, ...value }) => {
            trackSliderObject({
              tileGuid: tile.name,
              tileDirection,
              isPosStart: true,
              isBegin: true,
            });
            setAnimationMeta(value);
            setIsAnimating(true);
          },
        );
        ToneTransport.getInstance().emitter.once(
          `START_FINISH_${animationGuid}_${posToString(animation.posStart)}`,
          ({ motionDistanceInTiles, directionAngle, tileDirection }) => {
            trackSliderObject({
              tileGuid: tile.name,
              tileDirection,
              isPosStart: true,
              isBegin: false,
            });
            setIsAnimating(false);
            setAnimationMeta({});
            updatePosition({
              type: 'update',
              data: {
                x:
                  initialPosition.x +
                  tile.width * motionDistanceInTiles * Math.sin(directionAngle),
                y:
                  initialPosition.y -
                  tile.height *
                    motionDistanceInTiles *
                    Math.cos(directionAngle),
              },
            });
          },
        );
        ToneTransport.getInstance().emitter.once(
          `END_BEGIN_${animationGuid}_${posToString(animation.posEnd)}`,
          ({ tileDirection, ...value }) => {
            trackSliderObject({
              tileGuid: tile.name,
              tileDirection,
              isPosStart: false,
              isBegin: true,
            });
            setAnimationMeta(value);
            setIsAnimating(true);
          },
        );
        ToneTransport.getInstance().emitter.once(
          `END_FINISH_${animationGuid}_${posToString(animation.posEnd)}`,
          ({ tileDirection }) => {
            trackSliderObject({
              tileGuid: tile.name,
              tileDirection,
              isPosStart: false,
              isBegin: false,
            });
            updatePosition({
              type: 'update',
              data: { ...initialPosition },
            });
            setIsAnimating(false);
            setAnimationMeta({});
          },
        );
      });
      /* eslint-disable-next-line consistent-return */
      return () => {
        if (!animations || animations?.length === 0) return;

        animations.forEach(animation => {
          const animationGuid = animation?.guid?.replaceAll('-', '_'); // fix bug tone emitter
          ToneTransport.getInstance().clearEvent(
            `START_BEGIN_${animationGuid}_${posToString(animation.posStart)}`,
          );
          ToneTransport.getInstance().clearEvent(
            `START_FINISH_${animationGuid}_${posToString(animation.posStart)}`,
          );
          ToneTransport.getInstance().clearEvent(
            `END_BEGIN_${animationGuid}_${posToString(animation.posEnd)}`,
          );
          ToneTransport.getInstance().clearEvent(
            `END_FINISH_${animationGuid}_${posToString(animation.posEnd)}`,
          );
        });
      };
    }, [animationTypes]);

    const {
      motionDuration,
      motionDistanceInTiles,
      directionAngle,
      isIncrease,
    } = animationMeta;

    useTick(delta => {
      let newPosition;
      if (animationMeta && position) {
        const motionDistancePerMsInTiles =
          motionDistanceInTiles / motionDuration;

        const motionDistancePerFrameInTiles =
          motionDistancePerMsInTiles / PIXI.settings.TARGET_FPMS;

        const horizontalMotionDistancePerFrame =
          motionDistancePerFrameInTiles * tile.width;

        const verticalMotionDistancePerFrame =
          motionDistancePerFrameInTiles * tile.height;
        if (isIncrease) {
          newPosition = {
            x:
              position.x +
              delta *
                horizontalMotionDistancePerFrame *
                Math.sin(directionAngle),
            y:
              position.y -
              delta * verticalMotionDistancePerFrame * Math.cos(directionAngle),
          };
        } else {
          newPosition = {
            x:
              position.x -
              delta *
                horizontalMotionDistancePerFrame *
                Math.sin(directionAngle),
            y:
              position.y +
              delta * verticalMotionDistancePerFrame * Math.cos(directionAngle),
          };
        }
        if (newPosition) {
          updatePosition({
            type: 'update',
            data: newPosition,
          });
        }
      }
    }, isAnimating);
  }
  return textures && textures.length && textures.every(texture => texture) ? (
    <SpriteAnimated
      textures={textures}
      anchor={new ObservablePoint(() => {}, null, anchor.x, anchor.y)}
      scale={scale}
      rotation={rotation}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...position}
      animationSpeed={animationSpeed}
      isPlaying={isPlaying}
    />
  ) : null;
};
