/* eslint-disable no-prototype-builtins */
/* eslint-disable no-param-reassign */
import * as React from 'react';
import { Container, Text } from '@inlet/react-pixi';
import * as PIXI from 'pixi.js';
import SliderObject from 'handler/SliderObject';
import { store, selector, actions } from 'store';
import { QUESTION_TYPE, TILE_TYPE } from 'utils/constants';
import { getRandomListFromArray, checkObjectType } from 'utils/helper';
import { findTileSet, getGidByTileSetName } from './utils';
import Tile from './Tile';
import { getTileContext } from '../TileContext';

const getRandomTiles = (currentQuestion, length) => {
  const minLength = Math.min(currentQuestion?.tiles?.length, length);
  const list = getRandomListFromArray(
    currentQuestion?.tiles?.filter(t => t !== currentQuestion?.tile_correct),
    minLength - 1,
  );
  return getRandomListFromArray(
    [currentQuestion?.tile_correct, ...list],
    minLength,
  );
};

export default ({ layer, tileSets }) => {
  const [tilingSprites, setTilingSprites] = React.useState([]);
  const { tileText, currentQuestion } = getTileContext();
  const sliderObjects = selector.getSliderObjects(store?.state);

  React.useEffect(() => {
    let newTilingSprites = [];

    if (layer && layer.objects && tileSets) {
      let randomTileList = currentQuestion?.tiles || [];
      const sliderAnswers = [];
      const keyboardMappings = {};
      if (
        currentQuestion?.type === QUESTION_TYPE.TILE_LIST &&
        currentQuestion?.tiles_layout_randomize
      ) {
        const choiceTiles = layer.objects.filter(obj =>
          checkObjectType(obj, TILE_TYPE.CHOICE),
        );
        randomTileList = getRandomTiles(currentQuestion, choiceTiles?.length);
      }
      layer.objects.forEach(tile => {
        if (checkObjectType(tile, TILE_TYPE.KEYBOARD_MAPPING)) {
          // read keyboard mappings and add to store
          const input = tile?.properties?.input || '';
          const output = tile?.properties?.output;
          input?.split('').forEach(key => {
            keyboardMappings[key] = output;
          });
        }
        if (
          (checkObjectType(tile, TILE_TYPE.CHOICE) &&
            currentQuestion?.type === QUESTION_TYPE.TILE_LIST) ||
          checkObjectType(tile, TILE_TYPE.NBACK_CHOICE)
        ) {
          const tileID = randomTileList.length > 0 && randomTileList.shift();
          if (checkObjectType(tile, TILE_TYPE.CHOICE)) {
            sliderAnswers.push({ tileID, name: tile?.name });
          }
          if (tileID && sliderObjects?.hasOwnProperty(tileID)) {
            tile.gid = getGidByTileSetName(
              sliderObjects[tileID].gid,
              sliderObjects[tileID].sourceName,
              tileSets,
            );
            tile.height = sliderObjects[tileID].height;
            tile.visible = sliderObjects[tileID].visible;
            tile.rotation = sliderObjects[tileID].rotation;
          }
        }
        if (tile.gid) {
          const tileSet = findTileSet(tile.gid, tileSets);
          if (tileSet && tileSet.baseTexture) {
            let tileObject = null;
            const tileType = tile?.class ?? tile?.type;
            switch (tileType) {
              case TILE_TYPE.SLIDER:
                tileObject = (
                  <SliderObject
                    key={`${tile.gid}-${new Date().getTime() * Math.random()}`}
                    tile={tile}
                    tileSet={tileSet}
                    x={tile.x}
                    y={tile.y + 2 * tile.height}
                    visible={tile.visible}
                    animationSpeed={1}
                    isPlaying
                  />
                );
                break;
              default:
                tileObject = (
                  <Tile
                    key={`${tile.gid}-${new Date().getTime() * Math.random()}`}
                    tile={tile}
                    tileSet={tileSet}
                    x={tile.x}
                    y={tile.y + tile.height}
                    visible={tile.visible}
                    animationSpeed={1}
                    isPlaying
                  />
                );
                break;
            }
            newTilingSprites = [...newTilingSprites, tileObject];
          }
        } else if (tile.text && tile.text.main) {
          const {
            main,
            color,
            bold,
            fontfamily,
            fontsize,
            pixelsize,
            ...style
          } = tile.text;
          const textStyle = new PIXI.TextStyle({
            ...style,
            fill: color ?? '#000000',
            fontFamily: fontfamily,
            fontSize: Number(pixelsize || fontsize),
            fontWeight: bold ? 'bold' : '',
          });
          const text = checkObjectType(tile, 'description')
            ? tileText ?? tile?.text?.main
            : tile.text.main;
          const tileObject = (
            <Text
              key={new Date().getTime() * Math.random()}
              text={text}
              anchor={0.5}
              x={tile.x + tile.width / 2}
              y={tile.y + tile.height / 2}
              style={textStyle}
            />
          );
          newTilingSprites = [...newTilingSprites, tileObject];
        }
      });
      store.dispatch(actions.addSliderAnswers(sliderAnswers));
      store.dispatch(actions.addKeyboardMappings({ ...keyboardMappings }));
    }
    setTilingSprites(newTilingSprites);
  }, [layer, tileSets, tileText, currentQuestion]);

  return <Container alpha={layer.opacity}>{tilingSprites}</Container>;
};
