/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import withUserInfo from 'components/UserInfo/UserInfo';

const MainMenu = ({ isAdmin }) => (
  <div
    className="main-menu menu-fixed menu-dark menu-accordion menu-shadow "
    data-scroll-to-active="true"
  >
    <div className="main-menu-content">
      <ul
        className="navigation navigation-main"
        id="main-menu-navigation"
        data-menu="menu-navigation"
      >
        <li className="navigation-header">
          <span data-i18n="nav.category.layouts">
            <Link to="/">Home</Link>
          </span>
          <i
            className="ft-more-horizontal ft-minus"
            data-toggle="tooltip"
            data-placement="right"
            data-original-title="Layouts"
          ></i>
        </li>
        {isAdmin && (
          <>
            <li className="navigation-header">
              <span data-i18n="nav.category.layouts">
                <Link to="/history">History</Link>
              </span>
              <i
                className="ft-more-horizontal ft-minus"
                data-toggle="tooltip"
                data-placement="right"
                data-original-title="Layouts"
              ></i>
            </li>
            <li className=" navigation-header">
              <span data-i18n="nav.category.layouts">Admin</span>
              <i
                className="ft-more-horizontal ft-minus"
                data-toggle="tooltip"
                data-placement="right"
                data-original-title="Layouts"
              ></i>
            </li>
            <li className="nav-item">
              <Link to="/maps">
                <i className="icon-layers"></i>
                <span className="menu-title" data-i18n="nav.page_layouts.main">
                  Maps
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/studies">
                <i className="icon-layers"></i>
                <span className="menu-title" data-i18n="nav.page_layouts.main">
                  Studies
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/timings">
                <i className="icon-layers"></i>
                <span className="menu-title" data-i18n="nav.page_layouts.main">
                  Timings
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/questions">
                <i className="icon-layers"></i>
                <span className="menu-title" data-i18n="nav.page_layouts.main">
                  Questions
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/users">
                <i className="icon-layers"></i>
                <span className="menu-title" data-i18n="nav.page_layouts.main">
                  Users
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/data">
                <i className="icon-layers"></i>
                <span className="menu-title" data-i18n="nav.page_layouts.main">
                  Data
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/games">
                <i className="icon-layers"></i>
                <span className="menu-title" data-i18n="nav.page_layouts.main">
                  Games
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/sequences">
                <i className="icon-layers"></i>
                <span className="menu-title" data-i18n="nav.page_layouts.main">
                  Sequences
                </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/test-sequence">
                <i className="icon-layers"></i>
                <span className="menu-title" data-i18n="nav.page_layouts.main">
                  Test Sequence
                </span>
              </Link>
            </li>
          </>
        )}
      </ul>
    </div>
  </div>
);

export default withUserInfo(MainMenu);
