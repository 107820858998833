import React, { useEffect } from 'react';
import { Navbar } from 'components/Navbar';
import MainMenu from 'components/MainMenu';
import './Cabinet.scss';

// eslint-disable-next-line react/prop-types
export const CabinetLayout = ({ children }) => {
  useEffect(() => {
    document
      .querySelector('body')
      .classList.add(
        'vertical-layout',
        'vertical-menu',
        '2-columns',
        'menu-expanded',
        'fixed-navbar',
      );
    return () => {
      document.body.classList.remove(
        'vertical-layout',
        'vertical-menu',
        '2-columns',
        'menu-expanded',
        'fixed-navbar',
      );
    };
  }, []);

  return (
    <div>
      <Navbar showBrand />
      <div className="cabinet-layout-container">
        <MainMenu />
        <div className="app-content content">
          <div className="content-wrapper">
            <div className="content-header row"></div>
            <div className="content-body">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
