import React, { useState, useEffect, useMemo } from 'react';
import { BsPlay } from 'react-icons/bs';
import { CircularProgress } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useAxios } from 'utils/hooks';
import { toast } from 'react-toastify';
import { NEUX_URL } from 'utils/constants';
import EmptyImage from '@assets/images/icons/emptyImage.png';
import PlayingSequence from 'components/PlayingSequence';
import { getSequenceImagePath } from 'utils/format';
import './SequenceInfo.scss';

const SequenceInfo = () => {
  const { id } = useParams();
  const axiosInstance = useAxios(NEUX_URL);
  const [loading, setLoading] = useState(true);
  const [sequence, setSequence] = useState({});
  const [sequencePlay, setSequencePlay] = useState('');

  const imageHeader = useMemo(() => getSequenceImagePath(id, 'header'), [id]);

  const fetchData = async () => {
    try {
      setLoading(true);
      if (id) {
        const resp = await axiosInstance.get(`/sc/sequence/${id}`);
        if (resp?.data) {
          setSequence({
            imageHeader: getSequenceImagePath(id, 'header'),
            ...resp?.data,
          });
        }
      }
    } catch (error) {
      console.log('error', error);
      toast.error(error?.response?.data?.message ?? 'Error');
    } finally {
      setLoading(false);
    }
  };

  const onPlaySequence = async () => {
    try {
      const respSequencePlay = await axiosInstance.put('/sc/sequenceplay', {
        sequence_guid: id,
      });
      await setSequencePlay(respSequencePlay?.data?.guid);
      document.getElementsByClassName(
        'sequence-info-container',
      )[0].style.display = 'none';
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.log(error);
    }
  };

  const onCloseSequence = () => {
    setSequencePlay('');
    document.getElementsByClassName(
      'sequence-info-container',
    )[0].style.display = 'initial';
  };

  useEffect(() => {
    if (axiosInstance) {
      fetchData();
    }
  }, [axiosInstance]);

  const { name, description } = sequence;

  return (
    <>
      {loading ? (
        <div className="sequence-info-loading">
          <CircularProgress />
        </div>
      ) : (
        <div className="sequence-info-container">
          <div className="sequence-info-header">
            <img
              src={imageHeader}
              onError={e => {
                e.target.onerror = null;
                e.target.src = EmptyImage;
              }}
            />
          </div>
          <div className="sequence-info-main">
            <div className="sequence-info-name">{name}</div>
            <button
              type="button"
              className="btn btn-primary sequence-info-play"
              onClick={onPlaySequence}
            >
              <BsPlay />
            </button>
          </div>
          <div className="sequence-info-description">
            <h3>Description</h3>
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        </div>
      )}
      {sequencePlay && (
        <PlayingSequence
          sequence={sequence}
          sequencePlayGuid={sequencePlay}
          onClose={onCloseSequence}
        />
      )}
    </>
  );
};

export default SequenceInfo;
