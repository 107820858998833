import React, { useEffect } from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-dracula';
import './UpdateStudyEditor.scss';

export default ({ studyContent, setStudyContent, loading }) => {
  const [height, setHeight] = React.useState(
    document?.body?.getBoundingClientRect()?.height,
  );
  const onChangeContent = content => {
    setStudyContent(content);
  };

  const handleResizeEditor = () => {
    if (document?.body?.getBoundingClientRect()?.height !== height) {
      setHeight(document?.body?.getBoundingClientRect()?.height);
    }
  };

  useEffect(() => {
    return () => {
      window.removeEventListener('resize', handleResizeEditor, true);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResizeEditor, true);
  }, []);

  return (
    <div className="update-study-container">
      <div className="row study-content-group">
        <div className="col-lg-12">
          <AceEditor
            mode="json"
            theme="dracula"
            onChange={onChangeContent}
            name="study-content"
            width="100%"
            height={`${height - 180}px`}
            fontSize={12}
            splits={1}
            orientation="below"
            value={studyContent}
            readOnly={loading}
            showPrintMargin={false}
            setOptions={{ useWorker: false }}
          />
        </div>
      </div>
    </div>
  );
};
