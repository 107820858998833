import React from 'react';
import propTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import * as giIcon from 'react-icons/gi';
import classNames from 'classnames';

export const DRAGGABLE_TYPE = {
  DRAG: 'drag',
  DROP: 'drop',
};
export const DraggableGame = ({ game, index, type }) => (
  <Draggable
    key={`${game.guid}__${type}__${index}`}
    draggableId={`${game.guid}__${type}__${index}`}
    index={index}
  >
    {(provided, snapshot) => {
      const IconComponent = giIcon[game.icon];

      const gameContent = (
        <>
          <div
            className="col-md-2 col-sm-4 icon-demo"
            style={{ color: game.icon_color }}
          >
            {IconComponent && <IconComponent />}
          </div>
          <div className="col-md-10 col-sm-8 game-content">
            <div className="row">
              <div className="col-md-3 col-sm-12 game-bmp">BMP: 126.25</div>
              <div className="col-md-1" />
              <div className="col-md-3 col-sm-12 game-bars">BARS: 8:0:0</div>
              <div className="col-md-1" />
              <div className="col-md-3 col-sm-12 game-time">TIME 32.252s</div>
            </div>
            <div className="game-name">{game.name}</div>
            <div className="game-guid">{game.guid}</div>
          </div>
        </>
      );
      return (
        <>
          <div
            ref={provided.innerRef}
            // eslint-disable-next-line
            {...provided.draggableProps}
            // eslint-disable-next-line
            {...provided.dragHandleProps}
            style={{
              ...provided.draggableProps.style,
              ...(type === DRAGGABLE_TYPE.DRAG
                ? {
                    transform: snapshot.isDragging
                      ? provided.draggableProps.style?.transform
                      : 'translate(0px, 0px)',
                  }
                : {}),
            }}
            className={classNames('game-draggable-container row', {
              dragging: snapshot.isDragging,
            })}
          >
            {gameContent}
          </div>
          {type === DRAGGABLE_TYPE.DRAG && snapshot.isDragging && (
            <div
              style={{ transform: 'none !important' }}
              className="game-draggable-container row"
            >
              {gameContent}
            </div>
          )}
        </>
      );
    }}
  </Draggable>
);

DraggableGame.propTypes = {
  game: propTypes.exact({
    order: propTypes.number.isRequired,
    guid: propTypes.string.isRequired,
    icon: propTypes.string.isRequired,
    icon_color: propTypes.string.isRequired,
    level_map_guid: propTypes.string.isRequired,
    name: propTypes.string.isRequired,
    questions_guid: propTypes.string.isRequired,
    timings_guid: propTypes.string.isRequired,
  }).isRequired,
  index: propTypes.number.isRequired,
};

export default DraggableGame;
