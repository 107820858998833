import React, { useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Fade } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import Tiled from 'tiled';
import { formatMapUrlWithNeux } from 'utils/format';

import { useAxios } from 'utils/hooks';
import { NEUX_URL } from 'utils/constants';

const MapPreview = () => {
  const { id } = useParams();
  const history = useHistory();
  const axiosInstance = useAxios(NEUX_URL);

  const hideMenuTab = () => {
    document.getElementById('main-navbar').style.display = 'none';
    document.getElementsByClassName('main-menu')[0].style.display = 'none';
    document.getElementsByClassName('app-content')[0].style.marginLeft = '0px';
    document.getElementsByClassName('fixed-navbar')[0].style.paddingTop = '0px';
    document.getElementsByClassName('content-wrapper')[0].style.padding = '0px';
    document.getElementsByClassName('content-wrapper')[0].style.height =
      '100vh';
  };

  const openMenuTab = () => {
    document.getElementById('main-navbar').style.display = 'block';
    document.getElementsByClassName('main-menu')[0].style.display = 'block';
    document.getElementsByClassName('app-content')[0].style.marginLeft =
      '240px';
    if (document.getElementsByClassName('fixed-navbar')) {
      document.getElementsByClassName('fixed-navbar')[0].style.paddingTop =
        '4.57rem';
    }
    document.getElementsByClassName('content-wrapper')[0].style.padding =
      '2.2rem';
    document.getElementsByClassName('content-wrapper')[0].style.height =
      'calc(100vh - 84px)';
  };

  const onKeyDown = e => {
    e.preventDefault();
    history.push('/maps', {
      previewMap: id,
    });
  };

  useEffect(() => {
    if (axiosInstance) {
      hideMenuTab();
    }
    window.addEventListener('keydown', onKeyDown);
    return () => {
      openMenuTab();
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [axiosInstance]);

  return (
    <>
      <CssBaseline />
      <Fade
        in={Boolean(id)}
        timeout={{
          enter: 1000,
          exit: 0,
        }}
        mountOnEnter
        unmountOnExit
      >
        <div className="tileWrapper">
          <Tiled
            mapUrl={formatMapUrlWithNeux(id)}
            axiosInstance={axiosInstance}
          />
        </div>
      </Fade>
    </>
  );
};

export default MapPreview;
