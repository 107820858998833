import React, { useState, useEffect, useCallback } from 'react';
import { useAxios } from 'utils/hooks';
import { NEUX_URL } from 'utils/constants';
import { CircularProgress } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import PlayingSequence from 'components/PlayingSequence';
import { BsPlay } from 'react-icons/bs';

const DASHBOARD_CONTAINER_CLASSNAME = 'dashboard-home-container';

const DashboardHome = () => {
  const [loading, setLoading] = useState(true);
  const [sequenceGuid, setSequenceGuid] = useState('');
  const [sequencePlay, setSequencePlay] = useState('');
  const [sequence, setSequence] = useState({});
  const axiosInstance = useAxios(NEUX_URL);

  const onLoadData = useCallback(async () => {
    try {
      const resp = await axiosInstance.get('/sc/user/sequenceplay-dispatch');
      if (resp?.data?.sequence_guid) {
        setSequenceGuid(resp?.data?.sequence_guid);
        setSequencePlay(resp?.data?.sequenceplay_guid);
        return resp?.data?.sequence_guid;
      }
    } catch (error) {
      console.log('error', error);
    }
    return null;
  }, [axiosInstance]);

  const fetchDefaultData = useCallback(async () => {
    setLoading(true);
    const guid = await onLoadData();
    if (!guid) {
      setTimeout(async () => {
        await onLoadData();
      }, 2000);
    }
    setLoading(false);
  }, [onLoadData]);

  const onPlaySequence = async guid => {
    try {
      const { data } = await axiosInstance.get(`/sc/sequence/${guid}`);
      if (data) {
        setSequence(data);
      }
      document.getElementsByClassName(
        DASHBOARD_CONTAINER_CLASSNAME,
      )[0].style.display = 'none';
    } catch (error) {
      /* eslint-disable-next-line no-console */
      setSequence({});
      console.log(error);
    }
  };

  const onClickPlaySequence = useCallback(async () => {
    try {
      await onPlaySequence(sequenceGuid);
      document.getElementById('game-outside').requestFullscreen();
    } catch (error) {
      console.log('error');
    }
  }, [sequenceGuid]);

  const onCloseSequence = useCallback(async () => {
    const guid = await onLoadData();
    if (guid) {
      await onPlaySequence(guid);
    } else {
      window.location.reload(); // reset data
    }
  }, [onLoadData, onPlaySequence]);

  useEffect(() => {
    if (axiosInstance) {
      fetchDefaultData();
    }
    window.addEventListener('SEND_USER_INFO', fetchDefaultData);
    return () => {
      window.removeEventListener('SEND_USER_INFO', fetchDefaultData);
    };
  }, [axiosInstance]);

  return (
    <>
      <div id="game-outside">
        {loading ? (
          <div className="sequence-item-loading">
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className={DASHBOARD_CONTAINER_CLASSNAME}>
              <button
                type="button"
                className="btn btn-primary"
                disabled={!sequenceGuid || !sequencePlay}
                onClick={onClickPlaySequence}
              >
                <BsPlay fontSize="30px" />
                <span>Start</span>
              </button>
            </div>
            <PlayingSequence
              sequence={sequence}
              sequencePlayGuid={sequencePlay}
              onClose={onCloseSequence}
              isKeepingGameMode={!isEmpty(sequence)}
            />
          </>
        )}
      </div>
    </>
  );
};

export default DashboardHome;
