import React, { useRef, useState, useEffect, useCallback } from 'react';
import moment from 'dayjs';
import utc from 'dayjs/plugin/utc';
import DataTable from 'react-data-table-component';
import OverviewChart from 'components/OverviewChart/OverviewChart';
import {
  NEUX_URL,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
} from 'utils/constants';
import { useAxios } from 'utils/hooks';
import SequencePlayTable from './SequencePlayTable/SequencePlayTable';
import './SequenceOverview.scss';

moment.extend(utc);

const columns = [
  {
    name: 'Date Started',
    cell: ({ start_date: startDate }) =>
      moment(startDate)
        .utc()
        .local()
        .format('YYYY/MM/DD HH:mm'),
    center: true,
  },
  {
    name: 'Completed',
    cell: ({ is_completed: isCompleted }) => (isCompleted ? 'Yes' : 'No'),
    center: true,
  },
  {
    name: 'Duration',
    cell: ({ duration_ms: durationMs }) => `${durationMs} s`,
    center: true,
  },
  {
    name: 'Username',
    selector: 'username',
    center: true,
  },
  {
    name: 'Sequence Play GUID',
    width: '200px',
    height: 'auto',
    wrap: true,
    cell: ({ sequenceplay_guid: sequenceplayGuid }) => sequenceplayGuid,
    center: true,
  },
  {
    name: 'Games',
    selector: 'games_count',
    center: true,
  },
  {
    name: 'Questions Answered',
    selector: 'answers_count',
    center: true,
  },
  {
    name: 'Metrics Collected',
    selector: 'metrics_count',
    center: true,
  },
];

const SequenceOverview = ({ id }) => {
  const chartList = useRef();
  const [chartWidth, setChartWidth] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [overviewData, setOverviewData] = useState({});
  const [sequenceplayPagination, setSequenceplayPagination] = useState({
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    totalCount: 0,
  });
  const [sequenceplayData, setSequenceplayData] = useState([]);
  const axiosInstance = useAxios(NEUX_URL);

  const fetchOverviewData = async () => {
    try {
      const resp = await axiosInstance(`/sc/sequence/${id}/overview_statistic`);
      if (resp?.data.overview_statistic) {
        setOverviewData(resp?.data.overview_statistic);
      }
    } catch (error) {
      console.log('err', error);
    }
  };

  const fetchSequenceplayData = async ({ _page, _pageSize }) => {
    try {
      const resp = await axiosInstance(`/sc/sequence/${id}/sequenceplay_logs`, {
        params: { page: _page, page_size: _pageSize },
      });
      setSequenceplayData(resp.data.sequenceplay_logs);
      setSequenceplayPagination({
        page: resp?.data?.page,
        pageSize: resp?.data?.page_size,
        totalCount: resp?.data?.total_count,
      });
    } catch (error) {
      console.log('err', error);
    }
  };

  const handleResizeChart = () => {
    const width = chartList.current?.offsetWidth;
    if (width > 400) {
      setChartWidth(Math.floor((width - 100) / 3));
    } else {
      setChartWidth(Math.floor((width - 60) / 3));
    }
  };

  const onChangePage = useCallback(
    currentPage => {
      fetchSequenceplayData({
        _page: currentPage,
        _pageSize: sequenceplayPagination?.pageSize,
      });
    },
    [axiosInstance, sequenceplayPagination?.pageSize],
  );

  const fetchDefaultData = async () => {
    try {
      setIsLoading(true);
      await fetchOverviewData();
      await fetchSequenceplayData({
        _page: DEFAULT_PAGE,
        _pageSize: DEFAULT_PAGE_SIZE,
      });
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    window.addEventListener('resize', handleResizeChart);
    return () => {
      window.removeEventListener('resize', handleResizeChart);
    };
  }, []);

  useEffect(() => {
    handleResizeChart();
  }, [chartList.current]);

  useEffect(() => {
    if (axiosInstance) {
      fetchDefaultData();
    }
  }, [axiosInstance]);

  return (
    <div className="update-sequence-overview-container">
      <div ref={chartList} className="overview-chart-list">
        <OverviewChart
          width={chartWidth}
          changedNumber={overviewData?.total_players_changes}
          data={overviewData?.total_players}
          title="Total Plays"
        />
        <OverviewChart
          width={chartWidth}
          changedNumber={overviewData?.total_unique_users_changes}
          data={overviewData?.total_unique_users}
          title="Total Unique Users"
        />
        <OverviewChart
          width={chartWidth}
          changedNumber={overviewData?.game_completed_today_changes}
          data={overviewData?.game_completed_today}
          title="Game Completed Today"
          isHour
        />
      </div>
      <DataTable
        title="Latest Played Sequences Log"
        columns={columns}
        data={sequenceplayData}
        className="played-data-table"
        fixedHeader
        striped
        customStyles={{
          rows: {
            style: {
              fontSize: '15px',
            },
          },
          headCells: {
            style: {
              fontSize: '15px',
              fontWeight: 600,
            },
          },
        }}
        expandableRows
        expandableRowsComponent={<SequencePlayTable />}
        progressPending={isLoading}
        pagination
        paginationServer
        paginationTotalRows={sequenceplayPagination.totalCount}
        paginationDefaultPage={sequenceplayPagination.page}
        onChangePage={page => onChangePage(page)}
        paginationPerPage={sequenceplayPagination.pageSize}
        paginationRowsPerPageOptions={PAGE_SIZE_OPTIONS}
      />
    </div>
  );
};

export default SequenceOverview;
