import React from 'react';
import { GAME_MAP_TYPE } from 'utils/constants';
import { store, selector } from 'store';

export const remapSequence = sequence => {
  const remap = [];
  if (sequence?.welcome_map_guid) {
    remap.push({
      type: GAME_MAP_TYPE.WELCOME,
      welcome_map_guid: sequence?.welcome_map_guid,
    });
  }

  sequence?.games.forEach(item => {
    remap.push({
      type: GAME_MAP_TYPE.LEVEL,
      ...item,
    });

    if (item.questions_map_guid) {
      remap.push({
        type: GAME_MAP_TYPE.QUESTION,
        level_map_guid: item?.questions_map_guid,
        game_guid: item?.guid,
        questions_guid: item?.questions_guid,
        timings_guid: item?.timings_guid,
      });
    }
  });

  if (sequence?.finish_map_guid) {
    remap.push({
      type: GAME_MAP_TYPE.FINISH,
      finish_map_guid: sequence?.finish_map_guid,
    });
  }
  return {
    guid: sequence?.guid,
    name: sequence?.name,
    icon: sequence?.icon,
    bpm: sequence?.bpm,
    lifecycle: sequence?.lifecycle,
    icon_color: sequence?.icon_color,
    hints_visible: sequence?.hints_visible,
    result: remap,
  };
};

export const getColumns = ({ onPlaySequence }) => [
  {
    name: '',
    selector: 'guid',
    cell: ({ guid }) => (
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => onPlaySequence(guid)}
      >
        Play Sequence
      </button>
    ),
  },
  {
    name: 'NAME',
    selector: 'name',
  },
  {
    name: 'GAME COUNT',
    selector: 'game_count',
  },
];

export const getMappedTileByAnswer = answer => {
  const tile = selector
    .getListSliderAnswers(store?.state)
    .find(({ name }) => name === answer.toUpperCase());
  if (tile) return tile.tileID;
  return answer;
};
