/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useState, memo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useKeycloak } from '@react-keycloak/web';

import classnames from 'classnames';

import LogoSrc from '@assets/images/logo/logo-light.png';
import AvatarSrc from '@assets/images/icons/emptyAvatar.png';
import withUserInfo from '../UserInfo/UserInfo';

import './index.scss';

const Navba = ({ showBrand, user }) => {
  const [isShowProfile, setisShowProfile] = useState(false);
  // const history = useHistory();
  const { keycloak } = useKeycloak();
  const navContainerClassnames = classnames(
    'header-navbar navbar-expand-md navbar navbar-with-menu fixed-top navbar-shadow',
    {
      'navbar-semi-dark': showBrand,
      'navbar-light': !showBrand,
    },
  );

  const getClassShow = () => {
    return isShowProfile ? 'show' : '';
  };

  const onLogout = async () => {
    // temporary solution until we fix redirect_uri due to new Keycloak release
    // window.location.href = `${process.env.KEYCLOAK_URL}realms/${process.env.KEYCLOAK_REALM}/protocol/openid-connect/logout?redirect_uri=http%3A%2F%2Flocalhost%3A8080`;
    await keycloak.logout();
    // history.push('/');
  };

  return (
    <nav className={navContainerClassnames} id="main-navbar">
      <div className="my-navbar-container">
        <div className={`my-navbar-header ${showBrand ? 'dark-header' : ''}`}>
          <a className="navbar-brand" href="/">
            <img className="brand-logo" alt="Neux Science" src={LogoSrc} />
          </a>
        </div>
        <div className="my-navbar-content">
          {!keycloak.authenticated ? (
            <div className="row my-navbar-container__unauthorized-buttons">
              <Link className="nav-link nav-link-btn" to="/login">
                <button type="button" className="btn btn-primary">
                  Login
                </button>
              </Link>
              <Link className="nav-link nav-link-btn" to="/register">
                <button type="button" className="btn btn-primary">
                  Register
                </button>
              </Link>
            </div>
          ) : (
            <ul>
              <li
                onClick={() => setisShowProfile(!isShowProfile)}
                className={'dropdown dropdown-user nav-item ' + getClassShow()}
              >
                <a
                  className="dropdown-toggle nav-link dropdown-user-link"
                  data-toggle="dropdown"
                >
                  <span className="avatar avatar-online">
                    <img
                      src={user?.avatarUrl || AvatarSrc}
                      alt="avatar"
                      onError={e => {
                        e.target.onerror = null;
                        e.target.src = AvatarSrc;
                      }}
                    />
                    <i></i>
                  </span>
                  <span className="user-name">{user.username}</span>
                </a>
                <div
                  className={
                    'dropdown-menu dropdown-menu-right ' + getClassShow()
                  }
                >
                  {/* <a className="dropdown-item" href="chat-application.html">
                    <i className="ft-message-square"></i> Chats
                  </a>
                  <div className="dropdown-divider"></div> */}
                  <a className="dropdown-item" onClick={onLogout}>
                    <i className="ft-power"></i> Logout
                  </a>
                </div>
              </li>
            </ul>
          )}
        </div>
      </div>
    </nav>
  );
};

export const Navbar = withUserInfo(
  memo(
    Navba,
    (prevProps, nextProps) =>
      prevProps?.showBrand === nextProps?.showBrand &&
      prevProps?.user?.username === nextProps?.user?.username &&
      prevProps?.user?.avatarUrl !== nextProps?.user?.avatarUrl,
  ),
);

Navbar.defaultProps = {
  showBrand: false,
  user: null,
};

Navbar.propTypes = {
  showBrand: PropTypes.bool,
  user: PropTypes.shape({}),
};
