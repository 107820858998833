import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAxios } from 'utils/hooks';
import { NEUX_URL } from 'utils/constants';
import PageHeader, { ButtonType } from 'components/PageHeader';

import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-dracula';
import './UpdateQuestion.scss';

export default () => {
  const { id } = useParams();
  const history = useHistory();

  const [height, setHeight] = React.useState(
    document?.body?.getBoundingClientRect()?.height,
  );
  const [idQuestion, setIdQuestion] = useState(id);
  const [loading, setLoading] = React.useState(false);
  const [questionContent, setQuestionContent] = React.useState('');
  const [question, setQuestion] = React.useState({
    name: '',
    lifecycle: '',
  });

  const axiosInstance = useAxios(NEUX_URL);

  const onChangeContent = content => {
    setQuestionContent(content);
  };

  const handleResizeEditor = () => {
    if (document?.body?.getBoundingClientRect()?.height !== height) {
      setHeight(document?.body?.getBoundingClientRect()?.height);
    }
  };

  const onSaveQuestion = useCallback(
    async values => {
      try {
        setLoading(true);
        if (idQuestion) {
          await axiosInstance.post('sc/questions/', {
            data: btoa(questionContent),
            guid: id,
            ...values,
          });
        } else {
          const { data } = await axiosInstance.put('sc/questions', {
            data: btoa(questionContent),
            ...values,
          });

          setIdQuestion(data?.guid);
          window.history.replaceState(null, '', `/question/${data.guid}`);
        }
        setQuestion({
          name: values?.name,
          lifecycle: values?.lifecycle,
        });
        setLoading(false);
        toast.success('Saved');
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      }
    },
    [questionContent, axiosInstance, idQuestion, id],
  );

  const fetchQuestionData = async () => {
    try {
      setLoading(true);
      const resp = await axiosInstance.get(`sc/questions/${id}`);
      if (resp && resp.data) {
        setQuestionContent(atob(resp.data.data));
        setQuestion({
          name: resp?.data?.name,
          lifecycle: resp?.data?.lifecycle,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      window.removeEventListener('resize', handleResizeEditor, true);
    };
  }, []);

  useEffect(() => {
    if (id && axiosInstance) {
      fetchQuestionData();
    }
    window.addEventListener('resize', handleResizeEditor, true);
  }, [axiosInstance]);

  return (
    <PageHeader
      guid={idQuestion}
      defaultName={question?.name}
      editor={
        <AceEditor
          mode="json"
          theme="dracula"
          onChange={onChangeContent}
          name="question-content"
          width="100%"
          height={`${height - 180}px`}
          fontSize={12}
          splits={1}
          orientation="below"
          value={questionContent}
          readOnly={loading}
          showPrintMargin={false}
          setOptions={{ useWorker: false }}
        />
      }
      defaultTab={ButtonType.EDITOR}
      showLifecycle
      defaultLifecycle={question?.lifecycle}
      isLoading={loading}
      onSave={onSaveQuestion}
      onCancel={() => {
        history.push('/questions', {
          previewQuestion: id,
        });
      }}
    />
  );
};
