import React, { useState, useCallback } from 'react';
import { PAGE_SIZE_OPTIONS, SEARCHABLE_TABLE_LIFECYCLE } from 'utils/constants';
import DataTable from 'react-data-table-component';
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
} from '@material-ui/core';
import { FaSearch } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import './SearchableTable.scss';

export default ({
  title,
  columns,
  data,
  loading,
  onSort,
  onChangePage,
  pagination,
  onSearch,
  isSingleSearch,
}) => {
  const [searchStr, setSearchStr] = useState(pagination?.searchStr);
  const [lifeCycle, setLifeCycle] = useState(pagination?.lifeCycle);

  const onSearchChange = evt => {
    setSearchStr(evt?.target?.value);
  };

  const onClearSearch = useCallback(() => {
    setSearchStr('');
    setLifeCycle('');
    onSearch('');
  }, [onSearch]);

  const onKeyDown = useCallback(
    evt => {
      if (evt.keyCode === 13) {
        // Enter
        if (isSingleSearch) {
          setLifeCycle('');
          onSearch(searchStr.trim(), '');
        } else {
          onSearch(searchStr.trim(), lifeCycle);
        }
      }
    },
    [searchStr, lifeCycle, onSearch, isSingleSearch],
  );

  const onChangeLifeCycle = useCallback(
    value => {
      setLifeCycle(prev => {
        let newValue = value;
        if (prev === value) {
          // unset
          newValue = '';
        }
        if (isSingleSearch) {
          setSearchStr('');
          onSearch('', newValue);
        } else {
          onSearch(searchStr.trim(), newValue);
        }
        return newValue;
      });
    },
    [searchStr, isSingleSearch, onSearch],
  );

  return (
    <DataTable
      title={
        <div className="row searchable__title">
          <div className="col-lg-3 col-md-12">{title}</div>
          {onSearch && (
            <div className="row col-lg-9 col-md-12 searchable__title__container">
              <div className="col-lg-7 col-md-12 searchable__title__lifecycle-button-container">
                {Object.entries(SEARCHABLE_TABLE_LIFECYCLE).map(
                  ([key, value]) => (
                    <Button
                      value={value}
                      key={key}
                      onClick={() => onChangeLifeCycle(value)}
                      disabled={loading && value !== lifeCycle}
                      variant={lifeCycle === value ? 'contained' : 'outlined'}
                      color={lifeCycle === value ? 'primary' : undefined}
                    >
                      {key?.toUpperCase()}
                    </Button>
                  ),
                )}
              </div>
              <TextField
                placeholder="Search..."
                aria-describedby="basic-addon1"
                onChange={onSearchChange}
                onKeyDown={onKeyDown}
                value={searchStr}
                variant="outlined"
                className="col-lg-4 col-md-12 searchable__title__search row"
                disabled={loading}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FaSearch />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={onClearSearch} edge="end">
                        <MdClose />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          )}
        </div>
      }
      columns={columns}
      data={data}
      className="data-table"
      pagination
      striped
      style={{
        cursor: 'pointer',
      }}
      progressPending={loading}
      onSort={onSort}
      paginationServer
      sortServer
      paginationTotalRows={pagination.totalCount}
      paginationDefaultPage={pagination.page}
      onChangePage={onChangePage}
      paginationPerPage={pagination.pageSize}
      paginationRowsPerPageOptions={PAGE_SIZE_OPTIONS}
    />
  );
};
