import React, { Component } from 'react';
import { store } from 'store';

const connect = mapStateToProps => Comp => {
  return class extends Component {
    componentDidMount() {
      this.unsubscribe = store.subscribe(this.handleChange.bind(this));
    }

    componentWillUnmount() {
      this.unsubscribe();
    }

    handleChange() {
      this.forceUpdate();
    }

    render() {
      return <Comp {...this.props} {...mapStateToProps(store.getState())} />;
    }
  };
};

export default connect;
