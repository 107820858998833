import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useAxios } from 'utils/hooks';
import {
  NEUX_URL,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  SEQUENCE_LIFECYCLE_VALUES,
} from 'utils/constants';
import { getPagitionFromUrl, setUrlFromPagition } from 'utils/helper';
import SearchableTable from 'components/SearchableTable';
import './Timing.scss';

const columns = [
  {
    name: 'GUID',
    selector: 'guid',
  },
  {
    name: 'NAME',
    selector: 'name',
    sortable: true,
    cell: ({ guid, name }) => <Link to={`/timing/${guid}`}>{name}</Link>,
  },
  {
    name: 'CREATED_UTC',
    selector: 'created_utc',
  },
  {
    name: 'UPDATED_UTC',
    selector: 'updated_utc',
    sortable: true,
  },
];

export default () => {
  const [loading, setLoading] = React.useState(false);
  const [listData, setListData] = React.useState([]);
  const axiosInstance = useAxios(NEUX_URL);
  const paginationQuery = getPagitionFromUrl();

  const [pagination, setPagination] = useState({
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    totalCount: 0,
    sortStr: 'newest',
    searchStr: '',
    lifeCycle: SEQUENCE_LIFECYCLE_VALUES.PUBLISHED,
    ...paginationQuery,
  });

  const fetchTimingData = async ({ sort, page, search, lifeCycle }) => {
    try {
      setLoading(true);
      let url = '/sc/timings/search';
      if (search) {
        url = `/sc/timings/search/name/${encodeURI(search)}`;
      } else if (lifeCycle) {
        url = `/sc/timings/search/lifecycle/${encodeURI(lifeCycle)}`;
      }
      const resp = await axiosInstance.get(`${url}${sort ? `/${sort}/` : ''}`, {
        params: { page: page || 1 },
      });
      if (resp && resp.data && resp.data.timings) {
        setListData(resp.data.timings);
        const newPagition = {
          sortStr: sort,
          page: resp?.data?.page,
          pageSize: resp?.data?.page_size,
          totalCount: resp?.data?.total_count,
          searchStr: search,
          lifeCycle,
        };
        setPagination(newPagition);
        setUrlFromPagition(newPagition);
      } else {
        setListData([]);
        setPagination(prev => {
          const newPagination = {
            ...prev,
            page: 1,
            totalCount: 0,
            searchStr: search,
            lifeCycle,
          };
          setUrlFromPagition(pagination);
          return newPagination;
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onSort = useCallback(
    (selectedColumn, sortDirection) => {
      let sortStr = '';
      if (sortDirection) {
        if (selectedColumn?.name === 'UPDATED_UTC') {
          sortStr = sortDirection === 'asc' ? 'newest' : 'oldest';
        } else {
          sortStr = sortDirection;
        }
      }
      fetchTimingData({
        sort: sortStr,
        search: pagination?.searchStr,
        lifeCycle: pagination?.lifeCycle,
      });
    },
    [axiosInstance, pagination],
  );

  const onSearch = useCallback(
    (searchStr, lifeCycle) => {
      fetchTimingData({
        sort: pagination?.sortStr,
        search: searchStr,
        lifeCycle,
      });
    },
    [axiosInstance, pagination],
  );

  const onChangePage = useCallback(
    currentPage => {
      if (currentPage === pagination?.page) return;
      fetchTimingData({
        sort: pagination?.sortStr,
        page: currentPage,
        search: pagination?.searchStr,
        lifeCycle: pagination?.lifeCycle,
      });
    },
    [axiosInstance, pagination],
  );

  useEffect(() => {
    if (axiosInstance) {
      fetchTimingData({
        sort: pagination?.sortStr,
        lifeCycle: pagination?.lifeCycle,
        page: pagination?.page,
        search: pagination?.searchStr,
      });
    }
  }, [axiosInstance]);

  return (
    <>
      <div className="button-group">
        <Link to="/add-timing">
          <button
            type="button"
            className="btn btn-primary add-new-timing-button"
          >
            Add New Timing
          </button>
        </Link>
      </div>
      <SearchableTable
        title="Timings"
        onSort={onSort}
        onSearch={onSearch}
        columns={columns}
        data={listData}
        loading={loading}
        pagination={pagination}
        onChangePage={onChangePage}
        isSingleSearch
      />
    </>
  );
};
