import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useAxios } from 'utils/hooks';
import moment from 'dayjs';
import { NEUX_URL } from 'utils/constants';
import '../SequenceOverview.scss';

const columns = [
  {
    name: 'Client Event UTC',
    cell: ({ client_event_utc: clientEventUtc }) =>
      moment(clientEventUtc)
        .utc()
        .local()
        .format('YYYY/MM/DD HH:mm:ss:SSS'),
    selector: 'guid',
  },
  {
    name: 'Event Type',
    cell: ({ event_type: eventType }) => (
      <span className="sequenceplay-no-break">{eventType}</span>
    ),
  },
  {
    name: 'Game Index',
    selector: 'game_sequence_index',
    center: true,
  },
  {
    name: 'Question Index',
    selector: 'question_index',
    center: true,
  },
  {
    name: 'Question Name',
    selector: 'question_name',
    center: true,
  },
  {
    name: 'Tile Guid',
    selector: 'tile_guid',
    center: true,
  },
  {
    name: 'Key Pressed',
    selector: 'key_pressed',
    center: true,
  },
  {
    name: 'Bpm',
    selector: 'bpm',
    center: true,
  },
  {
    name: 'Transport Position',
    selector: 'transport_pos',
    center: true,
  },
  {
    name: 'Sequence Pos Ms',
    selector: 'sequence_pos_ms',
    center: true,
  },
];

const SequencePlayTable = ({ data }) => {
  const [sequenceplayLogs, setSequencePlayLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const axiosInstance = useAxios(NEUX_URL);

  const fetchSequenceplayLogs = async ({ sequenceplay_guid: id }) => {
    try {
      setIsLoading(true);
      const resp = await axiosInstance(`/sc/sequenceplay/${id}`);
      if (resp?.data?.data) {
        const sortData = resp.data.data.sort(
          ({ client_event_utc: a }, { client_event_utc: b }) =>
            new Date(a) - new Date(b),
        );
        setSequencePlayLogs(sortData);
      }
    } catch (error) {
      console.log('err', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (axiosInstance) {
      fetchSequenceplayLogs(data);
    }
  }, [data, axiosInstance]);

  return (
    <div className="sequenceplay-overview-table">
      <DataTable
        title="Sequence Play Detail"
        columns={columns}
        data={sequenceplayLogs}
        className="data-table"
        striped
        customStyles={{
          rows: {
            style: {
              fontSize: '15px',
            },
          },
          headCells: {
            style: {
              fontSize: '15px',
              fontWeight: 600,
            },
          },
        }}
        progressPending={isLoading}
      />
    </div>
  );
};

export default SequencePlayTable;
