import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useAxios } from 'utils/hooks';
import { NEUX_URL, DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'utils/constants';
import SearchableTable from 'components/SearchableTable';
// import './Studies.scss';

export default () => {
  // const location = useLocation();
  const [loading, setLoading] = React.useState(false);
  const [pagination, setPagination] = useState({
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    totalCount: 0,
    // sortStr: 'newest',
    // searchStr: '',
  });
  const [listData, setListData] = React.useState([]);
  const axiosInstance = useAxios(NEUX_URL);

  const fetchUsersData = async ({ page }) => {
    try {
      setLoading(true);
      // const url = search
      //   ? `/sc/study/search/name/${encodeURI(search)}`
      //   : 'sc/study/list';
      const resp = await axiosInstance.get('/sc/user/search', {
        params: { page: page || 1 },
      });
      if (resp && resp.data && resp.data.users) {
        setListData(resp?.data?.users);
        setPagination({
          // sortStr: sort,
          page: resp?.data?.page,
          pageSize: resp?.data?.page_size,
          totalCount: resp?.data?.total_count,
          // searchStr: search,
        });
      } else {
        setListData([]);
        setPagination(prev => ({
          ...prev,
          page: 1,
          totalCount: 0,
        }));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  // const onSort = useCallback(
  //   (selectedColumn, sortDirection) => {
  //     let sortStr = '';
  //     if (sortDirection) {
  //       if (selectedColumn?.name === 'UPDATED_UTC') {
  //         sortStr = sortDirection === 'asc' ? 'newest' : 'oldest';
  //       } else {
  //         sortStr = sortDirection;
  //       }
  //     }
  //     fetchUsersData({ sort: sortStr });
  //   },
  //   [axiosInstance],
  // );

  // const onSearch = useCallback(
  //   searchStr => {
  //     fetchUsersData({
  //       sort: pagination?.sortStr,
  //       page: pagination?.page,
  //       search: searchStr,
  //     });
  //   },
  //   [axiosInstance, pagination],
  // );

  const onChangePage = useCallback(
    currentPage => {
      if (currentPage === pagination?.page) return;
      fetchUsersData({
        // sort: pagination?.sortStr,
        page: currentPage,
        // search: pagination?.searchStr,
      });
    },
    [axiosInstance, pagination],
  );

  const columns = useMemo(
    () => [
      {
        name: 'GUID',
        selector: 'guid',
        // cell: ({ first_name, last_name }) => (
        //   <Link
        //     to={`/study/${guid}`}
        //     className={
        //       location?.state?.previewStudy === guid
        //         ? 'preview-study-table'
        //         : ''
        //     }
        //   >
        //     {name}
        //   </Link>
        // ),
      },
      // {
      //   name: 'USERNAME',
      //   selector: 'username',
      // },
      // {
      //   name: 'NAME',
      //   cell: ({ first_name: firstName, last_name: lastName }) =>
      //     `${firstName || ''} ${lastName || ''}`.trim(),
      // },
      // {
      //   name: 'EMAIL',
      //   selector: 'email',
      // },
      {
        name: 'STUDY',
        cell: ({ study_guid }) => (
          <Link to={`/study/${study_guid}`}>{study_guid}</Link>
        ),
      },
    ],
    [],
  );

  useEffect(() => {
    if (axiosInstance) {
      fetchUsersData({ sort: pagination?.sortStr });
    }
  }, [axiosInstance]);

  return (
    <>
      <div className="button-group">
        {/* <Link to="/add-study">
          <button
            type="button"
            className="btn btn-primary add-new-study-button"
          >
            Add New Study
          </button>
        </Link> */}
      </div>
      <SearchableTable
        title="Users"
        // onSearch={onSearch}
        columns={columns}
        data={listData}
        loading={loading}
        // onSort={onSort}
        pagination={pagination}
        onChangePage={onChangePage}
      />
    </>
  );
};
