import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAxios } from 'utils/hooks';
import {
  NEUX_URL,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  SEQUENCE_LIFECYCLE_VALUES,
} from 'utils/constants';
import { getPagitionFromUrl, setUrlFromPagition } from 'utils/helper';
import SearchableTable from 'components/SearchableTable';
import * as giIcon from 'react-icons/gi';
import './Games.scss';

export default () => {
  const location = useLocation();
  const [loading, setLoading] = React.useState(false);
  const [listData, setListData] = React.useState([]);
  const paginationQuery = getPagitionFromUrl();
  const [pagination, setPagination] = useState({
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    totalCount: 0,
    sortStr: 'newest',
    searchStr: '',
    lifeCycle: SEQUENCE_LIFECYCLE_VALUES.PUBLISHED,
    ...paginationQuery,
  });
  const axiosInstance = useAxios(NEUX_URL);

  const columns = useMemo(
    () => [
      {
        name: '',
        width: '5%',
        cell: ({ icon, guid, icon_color: iconColor }) => {
          const IconComponent = giIcon[icon];
          return (
            <Link to={`/game/${guid}/preview`}>
              <div
                className="icon-column"
                style={{ color: iconColor || '#000000' }}
              >
                {IconComponent && <IconComponent />}
              </div>
            </Link>
          );
        },
      },
      {
        name: 'GUID',
        cell: ({ guid }) => (
          <div
            className={
              location?.state?.previewGame === guid ? 'preview-game-table' : ''
            }
          >
            {guid}
          </div>
        ),
      },
      {
        name: 'NAME',
        selector: 'name',
        cell: ({ name, guid }) => <Link to={`/game/${guid}`}>{name}</Link>,
        sortable: true,
      },
      {
        name: 'MAP NAME',
        cell: ({ level_map_guid, level_map_name }) => (
          <div>
            <div className="cell-name">{level_map_name}</div>
            <div className="cell-guid">{level_map_guid}</div>
          </div>
        ),
      },
      {
        name: 'TIMINGS NAME',
        cell: ({ timings_guid, timings_name }) => (
          <div>
            <div className="cell-name">{timings_name}</div>
            <div className="cell-guid">{timings_guid}</div>
          </div>
        ),
      },
      {
        name: 'QUESTIONS NAME',
        cell: ({ questions_guid, questions_name }) => (
          <div>
            <div className="cell-name">{questions_name}</div>
            <div className="cell-guid">{questions_guid}</div>
          </div>
        ),
      },
      {
        name: 'UPDATED_UTC',
        selector: 'updated_utc',
        sortable: true,
        cell: ({ updated_utc }) => (
          <div>
            <div className="cell-guid">{updated_utc}</div>
          </div>
        ),
      },
    ],
    [location?.search?.previewGame],
  );

  const fetchGameData = async ({ sort, page, search, lifeCycle }) => {
    try {
      setLoading(true);
      let url = 'sc/game/search';
      if (search) {
        url = `/sc/game/search/name/${encodeURI(search)}`;
      } else if (lifeCycle) {
        url = `/sc/game/search/lifecycle/${encodeURI(lifeCycle)}`;
      }
      const resp = await axiosInstance.get(`${url}${sort ? `/${sort}/` : ''}`, {
        params: { page: page || 1 },
      });
      if (resp && resp.data && resp.data.game) {
        setListData(resp.data.game);
        const newPagition = {
          sortStr: sort,
          page: resp?.data?.page,
          pageSize: resp?.data?.page_size,
          totalCount: resp?.data?.total_count,
          searchStr: search,
          lifeCycle,
        };
        setPagination(newPagition);
        setUrlFromPagition(newPagition);
      } else {
        setListData([]);
        setPagination(prev => {
          const newPagination = {
            ...prev,
            page: 1,
            totalCount: 0,
            searchStr: search,
            lifeCycle,
          };
          setUrlFromPagition(pagination);
          return newPagination;
        });
      }
      setLoading(false);
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  };

  const onSort = useCallback(
    (selectedColumn, sortDirection) => {
      let sortStr = '';
      if (sortDirection) {
        if (selectedColumn?.name === 'UPDATED_UTC') {
          sortStr = sortDirection === 'asc' ? 'newest' : 'oldest';
        } else {
          sortStr = sortDirection;
        }
      }
      fetchGameData({
        sort: sortStr,
        search: pagination?.searchStr,
        lifeCycle: pagination?.lifeCycle,
      });
    },
    [axiosInstance, pagination],
  );

  const onSearch = useCallback(
    (searchStr, lifeCycle) => {
      fetchGameData({
        sort: pagination?.sortStr,
        search: searchStr,
        lifeCycle,
      });
    },
    [axiosInstance, pagination],
  );

  useEffect(() => {
    if (axiosInstance) {
      fetchGameData({
        sort: pagination?.sortStr,
        lifeCycle: pagination?.lifeCycle,
        page: pagination?.page,
        search: pagination?.searchStr,
      });
    }
  }, [axiosInstance]);

  const onChangePage = useCallback(
    currentPage => {
      if (currentPage === pagination?.page) return;
      fetchGameData({
        sort: pagination?.sortStr,
        page: currentPage,
        search: pagination?.searchStr,
        lifeCycle: pagination?.lifeCycle,
      });
    },
    [axiosInstance, pagination],
  );

  return (
    <>
      <div id="game-outside">
        <div className="button-group">
          <Link to="/add-game">
            <button
              type="button"
              className="btn btn-primary add-new-game-button"
            >
              Add New Game
            </button>
          </Link>
        </div>
        <SearchableTable
          title="Games"
          onSearch={onSearch}
          columns={columns}
          data={listData}
          loading={loading}
          onSort={onSort}
          pagination={pagination}
          onChangePage={onChangePage}
          isSingleSearch
        />
      </div>
    </>
  );
};
