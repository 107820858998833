import React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import User from '../../Models/User';

const withUserInfo = BaseComponent => ({ bar, ...props }) => {
  const { keycloak } = useKeycloak();

  const getUser = () => {
    const user = new User();

    if (keycloak.authenticated) {
      const tp = keycloak.tokenParsed;
      user.username = tp.preferred_username;
      user.avatarUrl = tp.discord_avatar
        ? `https://cdn.discordapp.com/avatars/${tp.discord_id}/${tp.discord_avatar}.png?size=128`
        : '';
      user.groups = tp.groups;
    }

    return user;
  };

  const isAdmin = () => {
    if (keycloak.authenticated) {
      const tp = keycloak.tokenParsed;
      return (
        Array.isArray(tp?.groups) && tp?.groups.indexOf('web-admin') !== -1
      );
    }
    return false;
  };

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <BaseComponent {...props} user={getUser()} isAdmin={isAdmin()} />;
};

export default withUserInfo;
