import * as PIXI from 'pixi.js';
import { PixiComponent } from '@inlet/react-pixi';

export default PixiComponent('SpriteAnimated', {
  create: ({
    textures,
    anchor,
    scale,
    rotation,
    x,
    y,
    animationSpeed,
    isPlaying,
  }) => {
    const animatedSprite = new PIXI.AnimatedSprite(textures, true);
    animatedSprite.anchor = anchor;
    animatedSprite.scale = scale;
    animatedSprite.rotation = rotation;
    animatedSprite.x = x;
    animatedSprite.y = y;
    animatedSprite.animationSpeed = animationSpeed;
    animatedSprite.isPlaying = isPlaying;
    animatedSprite.roundPixels = true;
    return animatedSprite;
  },
  applyProps: (instance, _, { x, y }) => {
    instance.gotoAndPlay(0);

    if (instance.x !== x) {
      /* eslint-disable-next-line no-param-reassign */
      instance.x = x;
    }
    if (instance.y !== y) {
      /* eslint-disable-next-line no-param-reassign */
      instance.y = y;
    }
  },
});
