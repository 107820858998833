/* eslint-disable no-bitwise */
import { store, actions } from 'store';
import { LAYER_TYPE, TILE_TYPE } from 'utils/constants';
import { checkObjectType } from 'utils/helper';

const FLAG = {
  FLIPPED_HORIZONTALLY_FLAG: 0x80000000,
  FLIPPED_VERTICALLY_FLAG: 0x40000000,
};

export const FLIP = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
  DIAGONALLY: 'diagonally',
};

export function getFlip(tile) {
  if (!tile || !tile.gid) return '';
  const { gid } = tile;
  if (
    (gid & FLAG.FLIPPED_HORIZONTALLY_FLAG) !== 0 &&
    (gid & FLAG.FLIPPED_VERTICALLY_FLAG) !== 0
  ) {
    return FLIP.DIAGONALLY;
  }
  if ((gid & FLAG.FLIPPED_VERTICALLY_FLAG) !== 0) {
    return FLIP.VERTICAL;
  }
  if ((gid & FLAG.FLIPPED_HORIZONTALLY_FLAG) !== 0) {
    return FLIP.HORIZONTAL;
  }
  return '';
}

export function getGidByTileSetName(gid, name, tileSets) {
  const tileSet = tileSets.find(t => t.name === name);
  if (tileSet) {
    return gid + tileSet.firstGid;
  }
  return gid;
}

export function findTileSet(gid, tileSets) {
  return tileSets.find(
    t => t.firstGid <= gid && t.textures.length + t.firstGid > gid,
  );
}

export function getTextures(tile, tileSet) {
  const textures = [];

  if (tileSet && tileSet.textures && tileSet.textures.length) {
    if (tile && tile.animations && tile.animations.length) {
      tile.animations.forEach(frame => {
        textures.push(tileSet.textures[frame.tileId]);
      });
    } else {
      const flip = getFlip(tile);
      if (flip === '' && tileSet.firstGid <= tile.gid) {
        textures.push(tileSet.textures[tile.gid - tileSet.firstGid]);
      }
      let originalGid = 0;
      switch (flip) {
        case FLIP.VERTICAL: {
          originalGid = tile.gid - 2 ** 30;
          break;
        }
        case FLIP.HORIZONTAL: {
          originalGid = tile.gid - 2 ** 31;
          break;
        }
        case FLIP.DIAGONALLY: {
          originalGid = tile.gid - 2 ** 30 * 3;
          break;
        }
        default: {
          break;
        }
      }
      if (originalGid !== 0) {
        textures.push(tileSet.textures[originalGid - tileSet.firstGid]);
      }
    }
  }

  return textures;
}

const formatChoice = name => {
  let choice;
  switch (name) {
    case 'SPACE': {
      choice = ' ';
      break;
    }
    case 'SEMICOLON': {
      choice = ';';
      break;
    }
    default: {
      choice = name;
      break;
    }
  }
  return choice;
};

export const onLoadQuestionInfo = (mapLayers, tileSets) => {
  const listChoices = [];
  const listSliderObjects = [];

  mapLayers.forEach(layer => {
    if (layer.type === LAYER_TYPE.OBJECT && Array.isArray(layer.objects)) {
      layer?.objects.forEach(obj => {
        if (checkObjectType(obj, TILE_TYPE.SLIDER)) {
          const tileSet = findTileSet(obj.gid, tileSets);
          if (tileSet) {
            listSliderObjects.push({
              ...obj,
              gid: obj.gid - tileSet.firstGid,
              sourceName: tileSet.name,
            });
          }
        }
        if (
          checkObjectType(obj, TILE_TYPE.CHOICE) &&
          obj?.name &&
          formatChoice(obj?.name)
        ) {
          listChoices.push(formatChoice(obj?.name)?.toUpperCase());
        }
      });
    }
  });
  if (listChoices?.length > 0) {
    store.dispatch(actions.addSequenceChoices(listChoices));
  }
  if (listSliderObjects?.length > 0) {
    const object = [];
    listSliderObjects.forEach(obj => {
      object[obj.name] = obj;
    });
    store.dispatch(actions.addSliderObjects(object));
  }
};
