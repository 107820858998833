import queryString from 'query-string';

export function setCookie(name, value) {
  const date = new Date();
  date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000); // 30 days
  const expires = `expires=${date.toGMTString()}`;
  document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(
    value,
  )}; ${expires}; path=/`;
}

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) {
    const parsedValue = parts
      .pop()
      .split(';')
      .shift();
    return !!parsedValue && parsedValue !== 'undefined'
      ? parsedValue
      : undefined;
  }

  return undefined;
}

export const setLocalStorage = (name, value) => {
  localStorage.setItem(name, typeof value === 'undefined' ? value : '');
};

export const getLocalStorage = name => {
  return localStorage.getItem(name) || '';
};

export const getMilisecondCount = oldDate => {
  if (!oldDate) return 0;
  return Math.abs(new Date().getTime() - oldDate.getTime());
};

export const checkObjectType = (object, type) => {
  if (Array.isArray(type)) {
    return type.includes(object.type) || type.includes(object.class);
  }
  return type === object.class || type === object.type;
};

export const getRandomListFromArray = (list, length) => {
  const sample = [...list];
  const result = [];
  for (let i = 0; i < length; i += 1) {
    const random = Math.floor(Math.random() * sample.length);
    result.push(sample[random]);
    sample.splice(random, 1);
  }
  return result;
};

export const setUrlFromPagition = pagition => {
  const newUrl = queryString.stringifyUrl({
    url: window.location.pathname,
    query: pagition,
  });
  window.history.replaceState(null, '', newUrl);
};

export const getPagitionFromUrl = () =>
  queryString.parse(window.location?.search);
