/* eslint-disable no-console */
import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { hot } from 'react-hot-loader/root';
import Keycloak from 'keycloak-js';
import { KeycloakProvider } from '@react-keycloak/web';

// Styles
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-toastify/dist/ReactToastify.css';
// <!-- BEGIN VENDOR CSS-->
import './assets/css/robust-styles/vendors.css';
// <!-- END VENDOR CSS-->
// <!-- BEGIN ROBUST CSS-->
import './assets/css/robust-styles/app.css';
// <!-- END ROBUST CSS-->
// <!-- BEGIN Page Level CSS-->
import './assets/css/robust-styles/core/menu/menu-types/vertical-menu.css';
// <!-- END Page Level CSS-->
// <!-- BEGIN Custom CSS-->
import './assets/css/style.css';
// <!-- END Custom CSS-->
import { setCookie, getCookie } from 'utils/helper';

import { AppRouter } from './routes';

toast.configure();

class App extends Component {
  // eslint-disable-next-line class-methods-use-this
  get keycloak() {
    return new Keycloak({
      realm: process.env.KEYCLOAK_REALM,
      url: process.env.KEYCLOAK_URL,
      clientId: process.env.KEYCLOAK_CLIENT_ID,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  get keycloakProviderInitConfig() {
    return {
      onLoad: 'check-sso',
      checkLoginIframe: false,
      idToken: getCookie('idToken'),
      refreshToken: getCookie('refreshToken'),
      token: getCookie('token'),
    };
  }

  handleOnKeycloakEvent = (event, error) => {
    console.log('onKeycloakEvent', event, error);
  };

  handleOnKeycloakTokens = ({ idToken, refreshToken, token }) => {
    setCookie('idToken', idToken);
    setCookie('refreshToken', refreshToken);
    setCookie('token', token);
  };

  render() {
    return (
      <KeycloakProvider
        onEvent={this.handleOnKeycloakEvent}
        onTokens={this.handleOnKeycloakTokens}
        keycloak={this.keycloak}
        initConfig={this.keycloakProviderInitConfig}
        autoRefreshToken
      >
        <>
          <AppRouter />
          <ToastContainer />
        </>
      </KeycloakProvider>
    );
  }
}

export default hot(App);
