import React from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { CabinetLayout } from 'layouts/Cabinet';
import { BaseLayout } from 'layouts/Base';
import { useKeycloak } from '@react-keycloak/web';

// import PlayingGame from 'pages/games/PlayingGame';
// import PlayingSequence from 'pages/games/PlayingSequence';
import History from 'pages/games/History';
import Maps from 'pages/admin/Maps';
import Studies from 'pages/admin/Studies';
import Questions from 'pages/admin/Questions';
import Users from 'pages/admin/Users';
import Timings from 'pages/admin/Timings';
import Data from 'pages/admin/Data';
import UpdateTiming from 'pages/admin/UpdateTiming';
import UpdateQuestion from 'pages/admin/UpdateQuestion';
import UpdateMap from 'pages/admin/UpdateMap';
import DashBoardHome from 'pages/DashBoardHome';
import Games from 'pages/admin/Games';
import GamePreview from 'pages/admin/GamePreview';
import UpdateGame from 'pages/admin/UpdateGame';
import UpdateSequence from 'pages/admin/UpdateSequence';
import UpdateStudy from 'pages/admin/UpdateStudy';
import Sequence from 'pages/admin/Sequence';
import MapPreview from 'pages/admin/MapPreview';
import SequenceInfo from 'pages/admin/SequenceInfo';
import TestSequence from 'pages/admin/TestSequence';
import InviteStudy from 'pages/InviteStudy';
import CabinetPage from '../pages/Cabinet';
import LoginPage from '../pages/Login';
import RegistrationPage from '../pages/Registration';
import HomePage from '../pages/Home/Home';
import { PrivateRoute } from './utils';

const history = createBrowserHistory();

export const AppRouter = () => {
  const [keycloak, initialized] = useKeycloak();

  if (!initialized) {
    return <div>Loading...</div>;
  }

  const isAdmin =
    Array.isArray(keycloak?.tokenParsed?.groups) &&
    keycloak.tokenParsed.groups.indexOf('web-admin') !== -1;

  return (
    <Router history={history}>
      {!keycloak.authenticated ? (
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Route path="/register" component={RegistrationPage} />
          <BaseLayout>
            <Route path="/landing-page" component={InviteStudy} />
          </BaseLayout>
          <Route exact path="/" component={HomePage} />
          <Route path="*">
            <Redirect exact to="/" />
          </Route>
        </Switch>
      ) : (
        <CabinetLayout>
          {isAdmin ? (
            <Switch>
              <Route path="/landing-page" component={InviteStudy} />
              <PrivateRoute exact path="/" component={DashBoardHome} />
              <PrivateRoute path="/history" component={History} />
              <PrivateRoute path="/maps" component={Maps} />
              <PrivateRoute path="/studies" component={Studies} />
              <PrivateRoute path="/study/:id" component={UpdateStudy} />
              <PrivateRoute path="/add-study" component={UpdateStudy} />
              <PrivateRoute path="/questions" component={Questions} />
              <PrivateRoute path="/users" component={Users} />
              <PrivateRoute path="/timings" component={Timings} />
              <PrivateRoute path="/timing/:id" component={UpdateTiming} />
              <PrivateRoute path="/add-timing" component={UpdateTiming} />
              <PrivateRoute path="/question/:id" component={UpdateQuestion} />
              <PrivateRoute path="/add-question" component={UpdateQuestion} />
              <PrivateRoute exact path="/map/:id" component={UpdateMap} />
              <PrivateRoute
                exact
                path="/map/:id/preview"
                component={MapPreview}
              />
              <PrivateRoute path="/add-map" component={UpdateMap} />
              <PrivateRoute path="/data" component={Data} />
              <PrivateRoute path="/cabinet" component={CabinetPage} />
              <PrivateRoute path="/games" component={Games} />
              <PrivateRoute path="/add-game" component={UpdateGame} />
              <PrivateRoute exact path="/game/:id" component={UpdateGame} />
              <PrivateRoute
                exact
                path="/game/:id/preview"
                component={GamePreview}
              />
              <PrivateRoute path="/add-sequence" component={UpdateSequence} />
              <PrivateRoute
                exact
                path="/sequence/:id"
                component={UpdateSequence}
              />
              <PrivateRoute
                exact
                path="/sequence/info/:id"
                component={SequenceInfo}
              />
              <PrivateRoute path="/sequences" component={Sequence} />
              <PrivateRoute
                exact
                path="/test-sequence"
                component={TestSequence}
              />
              <Route path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
          ) : (
            <Switch>
              <PrivateRoute exact path="/" component={DashBoardHome} />
              <Route path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
          )}
        </CabinetLayout>
      )}
    </Router>
  );
};
