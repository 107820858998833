import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useAxios } from 'utils/hooks';
import {
  NEUX_URL,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  SEQUENCE_LIFECYCLE_VALUES,
} from 'utils/constants';
import { getPagitionFromUrl, setUrlFromPagition } from 'utils/helper';
import SearchableTable from 'components/SearchableTable';
import './Sequence.scss';

export default () => {
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const axiosInstance = useAxios(NEUX_URL);
  const paginationQuery = getPagitionFromUrl();

  const [pagination, setPagination] = useState({
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    totalCount: 0,
    sortStr: 'newest',
    searchStr: '',
    lifeCycle: SEQUENCE_LIFECYCLE_VALUES.PUBLISHED,
    ...paginationQuery,
  });

  const columns = [
    {
      name: 'GUID',
      selector: 'guid',
    },
    {
      name: 'NAME',
      selector: 'name',
      sortable: true,
      // eslint-disable-next-line
      cell: ({ name, guid }) => <Link to={`/sequence/${guid}`}>{name}</Link>,
    },
    {
      name: 'GAME COUNT',
      selector: 'game_count',
    },
  ];

  const fetchSequenceData = async ({ sort, page, search, lifeCycle }) => {
    try {
      setLoading(true);
      let url = '/sc/sequence/search';
      if (search) {
        url = `/sc/sequence/search/name/${encodeURI(search)}`;
      } else if (lifeCycle) {
        url = `/sc/sequence/search/lifecycle/${encodeURI(lifeCycle)}`;
      }
      const resp = await axiosInstance.get(`${url}${sort ? `/${sort}/` : ''}`, {
        params: { page: page || 1 },
      });
      if (resp?.data?.sequences) {
        setListData(resp.data.sequences);
        const newPagition = {
          sortStr: sort,
          page: resp?.data?.page,
          pageSize: resp?.data?.page_size,
          totalCount: resp?.data?.total_count,
          searchStr: search,
          lifeCycle,
        };
        setPagination(newPagition);
        setUrlFromPagition(newPagition);
      } else {
        setListData([]);
        setPagination(prev => {
          const newPagination = {
            ...prev,
            page: 1,
            totalCount: 0,
            searchStr: search,
            lifeCycle,
          };
          setUrlFromPagition(pagination);
          return newPagination;
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onSearch = useCallback(
    (searchStr, lifeCycle) => {
      fetchSequenceData({
        sort: pagination?.sortStr,
        search: searchStr,
        lifeCycle,
      });
    },
    [axiosInstance, pagination],
  );

  useEffect(() => {
    if (axiosInstance) {
      fetchSequenceData({
        sort: pagination?.sortStr,
        lifeCycle: pagination?.lifeCycle,
        page: pagination?.page,
        search: pagination?.searchStr,
      });
    }
  }, [axiosInstance]);

  const onSort = useCallback(
    (selectedColumn, sortDirection) => {
      let sortStr = '';
      if (sortDirection) {
        if (selectedColumn?.name === 'UPDATED_UTC') {
          sortStr = sortDirection === 'asc' ? 'newest' : 'oldest';
        } else {
          sortStr = sortDirection;
        }
      }
      fetchSequenceData({
        sort: sortStr,
        search: pagination?.searchStr,
        lifeCycle: pagination?.lifeCycle,
      });
    },
    [axiosInstance, pagination],
  );

  const onChangePage = useCallback(
    currentPage => {
      if (currentPage === pagination?.page) return;
      fetchSequenceData({
        sort: pagination?.sortStr,
        page: currentPage,
        search: pagination?.searchStr,
        lifeCycle: pagination?.lifeCycle,
      });
    },
    [axiosInstance, pagination],
  );

  return (
    <>
      <div className="button-group">
        <Link to="/add-sequence">
          <button
            type="button"
            className="btn btn-primary add-new-sequence-button"
          >
            Add New Sequence
          </button>
        </Link>
      </div>
      <SearchableTable
        title="Sequences"
        onSearch={onSearch}
        columns={columns}
        data={listData}
        loading={loading}
        onSort={onSort}
        pagination={pagination}
        onChangePage={onChangePage}
        isSingleSearch
      />
    </>
  );
};
