import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { NEUX_URL } from 'utils/constants';
import { toast } from 'react-toastify';
import { useAxios } from 'utils/hooks';
import { setCookie } from 'utils/helper';
import './InviteStudy.scss';

const InviteStudy = () => {
  const [study, setStudy] = useState();
  const [isLoading, setLoading] = useState(true);
  const axiosInstance = useAxios(NEUX_URL);
  const location = useLocation();
  const { study_guid: studyGuid } = queryString.parse(location?.search);

  const fetchData = async guid => {
    try {
      setLoading(true);
      const resp = await axiosInstance.get(`/sc/study/${guid}`);
      if (resp?.data) {
        setStudy(resp?.data);
        setCookie('study_guid', studyGuid);
      }
    } catch (error) {
      console.log('error', error);
      toast.error(error?.response?.data?.message ?? 'Error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (axiosInstance && studyGuid && studyGuid !== 'null') {
      fetchData(studyGuid);
    }
  }, [axiosInstance, studyGuid]);

  if (isLoading) return null;

  return (
    <div className="invite-study__container">
      YOU HAVE BEEN INVITED TO STUDY &quot;{study?.name || 'Unknown'}&quot;
    </div>
  );
};

export default InviteStudy;
