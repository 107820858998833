import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAxios } from 'utils/hooks';
import {
  NEUX_URL,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  SEQUENCE_LIFECYCLE_VALUES,
} from 'utils/constants';
import { getPagitionFromUrl, setUrlFromPagition } from 'utils/helper';
import SearchableTable from 'components/SearchableTable';
import './Studies.scss';

export default () => {
  const location = useLocation();
  const [loading, setLoading] = React.useState(false);
  const paginationQuery = getPagitionFromUrl();

  const [pagination, setPagination] = useState({
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGE_SIZE,
    totalCount: 0,
    sortStr: 'newest',
    searchStr: '',
    lifeCycle: SEQUENCE_LIFECYCLE_VALUES.PUBLISHED,
    ...paginationQuery,
  });
  const [listData, setListData] = React.useState([]);
  const axiosInstance = useAxios(NEUX_URL);

  const fetchStudiesData = async ({ page, sort, search, lifeCycle }) => {
    try {
      setLoading(true);
      let url = '/sc/study/search';
      if (search) {
        url = `/sc/study/search/name/${encodeURI(search)}`;
      } else if (lifeCycle) {
        url = `/sc/study/search/lifecycle/${encodeURI(lifeCycle)}`;
      }
      const resp = await axiosInstance.get(`${url}${sort ? `/${sort}/` : ''}`, {
        params: { page: page || 1 },
      });
      if (resp && resp.data && resp.data.studies) {
        setListData(resp?.data?.studies);
        const newPagition = {
          sortStr: sort,
          page: resp?.data?.page,
          pageSize: resp?.data?.page_size,
          totalCount: resp?.data?.total_count,
          searchStr: search,
          lifeCycle,
        };
        setPagination(newPagition);
        setUrlFromPagition(newPagition);
      } else {
        setListData([]);
        setPagination(prev => {
          const newPagination = {
            ...prev,
            page: 1,
            totalCount: 0,
            searchStr: search,
            lifeCycle,
          };
          setUrlFromPagition(pagination);
          return newPagination;
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const onSort = useCallback(
    (selectedColumn, sortDirection) => {
      let sortStr = '';
      if (sortDirection) {
        if (selectedColumn?.name === 'UPDATED_UTC') {
          sortStr = sortDirection === 'asc' ? 'newest' : 'oldest';
        } else {
          sortStr = sortDirection;
        }
      }
      fetchStudiesData({
        sort: sortStr,
        search: pagination?.searchStr,
        lifeCycle: pagination?.lifeCycle,
      });
    },
    [axiosInstance, pagination],
  );

  const onSearch = useCallback(
    (searchStr, lifeCycle) => {
      fetchStudiesData({
        sort: pagination?.sortStr,
        search: searchStr,
        lifeCycle,
      });
    },
    [axiosInstance, pagination],
  );

  const onChangePage = useCallback(
    currentPage => {
      if (currentPage === pagination?.page) return;
      fetchStudiesData({
        sort: pagination?.sortStr,
        page: currentPage,
        search: pagination?.searchStr,
        lifeCycle: pagination?.lifeCycle,
      });
    },
    [axiosInstance, pagination],
  );

  const columns = useMemo(
    () => [
      {
        name: 'GUID',
        selector: 'guid',
      },
      {
        name: 'NAME',
        selector: 'name',
        sortable: true,
        cell: ({ guid, name }) => (
          <Link
            to={`/study/${guid}`}
            className={
              location?.state?.previewStudy === guid
                ? 'preview-study-table'
                : ''
            }
          >
            {name}
          </Link>
        ),
      },
      {
        name: 'CREATED_UTC',
        selector: 'created_utc',
      },
      {
        name: 'UPDATED_UTC',
        sortable: true,
        selector: 'updated_utc',
      },
    ],
    [location?.state?.previewStudy],
  );

  useEffect(() => {
    if (axiosInstance) {
      fetchStudiesData({
        sort: pagination?.sortStr,
        lifeCycle: pagination?.lifeCycle,
        page: pagination?.page,
        search: pagination?.searchStr,
      });
    }
  }, [axiosInstance]);

  return (
    <>
      <div className="button-group">
        <Link to="/add-study">
          <button
            type="button"
            className="btn btn-primary add-new-study-button"
          >
            Add New Study
          </button>
        </Link>
      </div>
      <SearchableTable
        title="Studies"
        onSearch={onSearch}
        columns={columns}
        data={listData}
        loading={loading}
        onSort={onSort}
        pagination={pagination}
        onChangePage={onChangePage}
        isSingleSearch
      />
    </>
  );
};
