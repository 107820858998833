import React, { useEffect } from 'react';
import { Navbar } from '../../components/Navbar';

// eslint-disable-next-line react/prop-types
export const BaseLayout = ({ children }) => {
  useEffect(() => {
    document.body.classList.add('fixed-navbar');
    return () => {
      document.body.classList.remove('fixed-navbar');
    };
  }, []);

  return (
    <div>
      <Navbar />
      <div className="app-content content">
        <div className="content-wrapper">
          <div className="content-header row"></div>
          <div className="content-body">{children}</div>
        </div>
      </div>
    </div>
  );
};
