import React from 'react';
import { Container, Sprite } from '@inlet/react-pixi';
import { formatImageSource } from 'utils/format';

export default ({ layer }) => (
  <Container alpha={layer.opacity}>
    {layer.image && layer.image.source && (
      <Sprite source={formatImageSource(layer.image.source)} />
    )}
  </Container>
);
