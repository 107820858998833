import React, {
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from 'react';
import { Container, Graphics, useApp, useTick } from '@inlet/react-pixi';
import * as PIXI from 'pixi.js';
import TileCaching from 'services/TileCaching';
import tileMapLoader from './tiledMapLoader';
import TileSet from './TileSet';
import {
  TileLayer,
  ImageLayer,
  ObjectLayer,
  onLoadQuestionInfo,
} from './layers';

const TiledMapContainer = forwardRef(({ tiledPath, children }, ref) => {
  const app = useApp({
    autoResize: true,
    resolution: window.devicePixelRatio,
  });

  const [isMapReady, setIsMapReady] = useState(false);
  const [renderedOutput, setRenderedOutput] = useState(null);
  const [map, setMap] = useState(null);
  const containerRef = useRef(null);
  const [containerPosition, setContainerPosition] = useState({
    x: 0,
    y: 0,
  });
  const [scale, setScale] = useState(1);
  const [previousWindowWidth, setPreviousWindowWidth] = useState(0);
  const [previousWindowHeight, setPreviousWindowHeight] = useState(0);

  useImperativeHandle(ref, () => ({
    map,
    container: containerRef.current,
  }));

  const resizeHandler = () => {
    const parent = app.view.parentElement;
    const width = parent.clientWidth;
    const height = parent.clientHeight;

    app.renderer.resize(width, height);

    const mapWidth = (map?.width || 0) * (map?.tileWidth || 0);
    const mapHeight = (map?.height || 0) * (map?.tileHeight || 0);
    const mapScaleX = width / mapWidth;
    const mapScaleY = height / mapHeight;
    const scaleSize = Math.min(mapScaleX, mapScaleY);
    const mapPositionX = (width - mapWidth * scaleSize) / 2;
    const mapPositionY = (height - mapHeight * scaleSize) / 2;
    setContainerPosition({
      x: mapPositionX,
      y: mapPositionY,
    });

    if (scaleSize !== Infinity) {
      setScale(scaleSize);
    }
  };

  const updateViewSizes = () => {
    setIsMapReady(false);
    resizeHandler();
    setPreviousWindowWidth(app.view.parentNode.clientWidth);
    setPreviousWindowHeight(app.view.parentNode.clientHeight);
    setIsMapReady(true);
  };

  const getMapResource = path =>
    new Promise(resolve => {
      app.loader.reset();
      app.loader.add(path).load((loader, { [path]: resource }) => {
        if (resource) {
          tileMapLoader(loader, resource).then(resourceData => {
            resolve(resourceData);
          });
        }
      });
    });

  useTick(() => {
    if (
      previousWindowWidth !== app.view.parentNode.clientWidth ||
      previousWindowHeight !== app.view.parentNode.clientHeight
    ) {
      updateViewSizes();
    }
  }, app && map);

  useEffect(() => {
    setTimeout(updateViewSizes);
    return () => {
      app.loader.reset();
    };
  }, [map]);

  useEffect(() => {
    (async () => {
      if (tiledPath?.pathname) {
        let resourceData;
        if (TileCaching.getInitilize()) {
          resourceData = TileCaching.getFromCache(tiledPath.toString());
        }
        setRenderedOutput(null);
        if (!resourceData) {
          resourceData = await getMapResource(tiledPath.toString());
        }

        const { route, data: mapData } = resourceData;
        setMap(mapData);
        const tileSets = mapData.tileSets.map(
          tileSet => new TileSet(route, tileSet),
        );
        onLoadQuestionInfo(mapData?.layers, tileSets);
        if (mapData.backgroundColor) {
          app.renderer.backgroundColor = PIXI.utils.string2hex(
            mapData.backgroundColor,
          );
        }

        const output = (
          <>
            <Graphics
              draw={background => {
                background.beginFill(0xff0000, 0);
                background.drawRect(
                  0,
                  0,
                  (mapData.width || 0) * (mapData.tileWidth || 0),
                  (mapData.height || 0) * (mapData.tileHeight || 0),
                );
                background.endFill();
              }}
            />
            {mapData.layers.map((layerData, index) => {
              if (['tile', 'object'].includes(layerData.type)) {
                let LayerComponent;
                switch (layerData.type) {
                  case 'tile':
                    LayerComponent = TileLayer;
                    break;
                  case 'object':
                    LayerComponent = ObjectLayer;
                    break;
                  default:
                }
                return (
                  <LayerComponent
                    /* eslint-disable-next-line react/no-array-index-key */
                    key={`${layerData.type}_${index}`}
                    layer={layerData}
                    tileSets={tileSets}
                  />
                );
              }
              if (layerData.type === 'image') {
                return (
                  <ImageLayer
                    /* eslint-disable-next-line react/no-array-index-key */
                    key={`${layerData.type}_${index}`}
                    layer={layerData}
                    route={route}
                  />
                );
              }
              return null;
            })}
          </>
        );

        setRenderedOutput(output);
        updateViewSizes();
      }
    })();
  }, [tiledPath]); // eslint-disable-line

  if (!isMapReady) {
    return null;
  }

  // TODO check out z index for children and come up with strategy to have overlap
  return (
    <Container
      scale={{
        x: scale,
        y: scale,
      }}
      x={containerPosition.x}
      y={containerPosition.y}
      interactive
      ref={containerRef}
    >
      {renderedOutput}
      {children}
    </Container>
  );
});

export default TiledMapContainer;
