import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { NEUX_URL } from 'utils/constants';
import { getCookie, setCookie } from 'utils/helper';
import { useKeycloak } from '@react-keycloak/web';

export const useAxios = baseURL => {
  const [keycloak, initialized] = useKeycloak();
  const [axiosInstance, setAxiosInstance] = useState({});

  const onCheckUserInfo = async instance => {
    const studyGuid = getCookie('study_guid');
    if (!studyGuid || studyGuid === 'null') return;
    try {
      await instance.get('/sc/user/info', {
        params: { study_guid: studyGuid },
      });
      setCookie('study_guid', '');
      window.dispatchEvent(new Event('SEND_USER_INFO'));
    } catch (error) {
      console.log('error', error);
    }
  };

  const onCreateNewInstance = (_baseURL, _keycloak) => {
    const instance = axios.create({
      baseURL: _baseURL,
      headers: {
        /* eslint-disable-next-line no-nested-ternary */
        Authorization: !process.env.IS_DEVELOPED
          ? initialized
            ? `Bearer ${_keycloak.token}`
            : undefined
          : `Bearer ${process.env.TOKEN}`,
        'content-type': 'application/json',
      },
    });
    setAxiosInstance({ instance });
    if (_keycloak?.token) {
      onCheckUserInfo(instance);
    }
  };

  useEffect(() => {
    onCreateNewInstance(baseURL, keycloak);
    return () => {
      setAxiosInstance({});
    };
    // }, [baseURL, initialized]);
  }, [baseURL, initialized, keycloak, keycloak?.token]);

  return axiosInstance.instance;
};

export const useMetric = batchValue => {
  const [eventBatch] = useState(batchValue);
  const axiosInstance = useAxios(NEUX_URL);

  const sendMetricData = useCallback(
    ({
      welcomeMapGuid,
      finishMapGuid,
      levelMapGuid,
      eventType,
      sequencePlayGuid,
      sequenceGuid,
      gameGuid,
      gameSequenceIndex,
      questionsMapGuid,
      questionGuid,
      questionIndex,
      questionName,
      keyPressed,
      timedOut,
      timingsGuid,
      bpm,
      transportPos,
      tileGuid,
      sequencePosMs,
      tileDirection,
      answerTile,
    }) => {
      try {
        const clientEventUtc = new Date();
        axiosInstance.put('/sc/metric', {
          eventBatch,
          data: [
            {
              eventType,
              welcome_map_guid: welcomeMapGuid,
              finish_map_guid: finishMapGuid,
              level_map_guid: levelMapGuid,
              sequenceplay_guid: sequencePlayGuid,
              sequence_guid: sequenceGuid,
              game_guid: gameGuid,
              game_sequence_index: gameSequenceIndex,
              questions_map_guid: questionsMapGuid,
              question_guid: questionGuid,
              question_index: questionIndex,
              question_name: questionName,
              key_pressed: keyPressed,
              timed_out: timedOut,
              client_event_utc: clientEventUtc,
              timings_guid: timingsGuid,
              bpm,
              transport_pos: transportPos,
              tile_guid: tileGuid,
              sequence_pos_ms: sequencePosMs,
              tile_direction: tileDirection,
              answer_tile: answerTile,
            },
          ],
        });
      } catch (error) {
        /* eslint-disable-next-line no-console */
        console.log(error);
      }
    },
    [axiosInstance, eventBatch],
  );

  return { sendMetricData };
};
