export const ADD_SEQUENCE_CHOICE = 'ADD_SEQUENCE_CHOICE';
export const ADD_SEQUENCE_CHOICES = 'ADD_SEQUENCE_CHOICES';
export const CLEAR_SEQUENCE_CHOICE = 'CLEAR_SEQUENCE_CHOICE';
export const ADD_SLIDER_OBJECTS = 'ADD_SLIDER_OBJECTS';
export const CLEAR_SLIDER_OBJECTS = 'CLEAR_SLIDER_OBJECT';
export const ADD_SLIDER_ANSWERS = 'ADD_SLIDER_ANSWERS';
export const CLEAR_SLIDER_ANSWERS = 'CLEAR_SLIDER_ANSWERS';

export const ADD_KEYBOARD_MAPPINGS = 'ADD_KEYBOARD_MAPPINGS';
export const CLEAR_KEYBOARD_MAPPINGS = 'CLEAR_KEYBOARD_MAPPINGS';

export const addSequenceChoice = choice => ({
  type: ADD_SEQUENCE_CHOICE,
  payload: {
    choice: choice.toUpperCase(),
  },
});

export const addSequenceChoices = listChoices => ({
  type: ADD_SEQUENCE_CHOICES,
  payload: {
    listChoices,
  },
});

export const clearSequenceChoice = () => ({
  type: CLEAR_SEQUENCE_CHOICE,
});

export const addSliderObjects = object => ({
  type: ADD_SLIDER_OBJECTS,
  payload: {
    object,
  },
});

export const clearSliderObjects = () => ({
  type: CLEAR_SLIDER_OBJECTS,
});

export const addSliderAnswers = listSliderAnswers => ({
  type: ADD_SLIDER_ANSWERS,
  payload: {
    listSliderAnswers,
  },
});

export const clearSliderAnswers = () => ({
  type: CLEAR_SLIDER_ANSWERS,
});

export const addKeyboardMappings = keyboardMappings => ({
  type: ADD_KEYBOARD_MAPPINGS,
  payload: {
    keyboardMappings,
  },
});

export const clearKeyboardMappings = () => ({
  type: CLEAR_KEYBOARD_MAPPINGS,
});
