import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useAxios } from 'utils/hooks';
import { NEUX_URL } from 'utils/constants';
import TileCaching from 'services/TileCaching';
import PlayGame from 'components/PlayGame';

export default () => {
  const { id } = useParams();
  const history = useHistory();
  const [game, setGame] = useState(null);
  const axiosInstance = useAxios(NEUX_URL);

  const fetchGameData = async () => {
    try {
      const resp = await axiosInstance.get(`/sc/game/${id}`);
      if (resp && resp.data) {
        await TileCaching.setCachesFromGame(resp.data);
        setGame(resp.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onStopGame = () => {
    history.push('/games', {
      previewGame: id,
    });
  };

  const onKeyDown = e => {
    e.preventDefault();
    if (e.keyCode === 27) {
      // ESC button
      setGame(null);
      history.push('/games', {
        previewGame: id,
      });
    }
  };

  useEffect(() => {
    if (axiosInstance) {
      fetchGameData();
    }
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [axiosInstance]);

  if (!game) return null;

  return (
    <PlayGame
      map={game?.level_map_guid}
      timing={game?.timings_guid}
      questionUrl={game?.questions_guid}
      questionMapUrl={game?.questions_map_guid}
      onStopGame={onStopGame}
    />
  );
};
