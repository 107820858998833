import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAxios } from 'utils/hooks';
import { NEUX_URL } from 'utils/constants';
import PageHeader, { ButtonType } from 'components/PageHeader';
import UpdateStudyEditor from './UpdateStudyEditor/UpdateStudyEditor';
import UpdateStudyDash from './UpdateStudyDash/UpdateStudyDash';
import './UpdateStudy.scss';

export default () => {
  const { id, tab } = useParams();
  const history = useHistory();

  const axiosInstance = useAxios(NEUX_URL);

  const [study, setStudy] = useState({});
  const [studyContent, setStudyContent] = React.useState('');
  const [idStudy, setIdStudy] = React.useState(id);
  const [isLoading, setLoading] = useState(false);

  const fetchStudyData = async () => {
    try {
      setLoading(true);
      const resp = await axiosInstance.get(`sc/study/${id}`);
      if (resp && resp.data) {
        setStudy(resp.data);
        setStudyContent(resp.data.data ? atob(resp.data.data) : '');
      }
      setLoading(false);
    } catch (error) {
      console.log('error', error);
      setLoading(false);
    }
  };

  const onSaveStudy = useCallback(
    async ({ name, lifecycle, is_open }) => {
      try {
        setLoading(true);
        const params = {
          data: btoa(studyContent),
          name: name ?? study?.name,
          lifecycle: lifecycle ?? study?.lifecycle,
          is_open: is_open ?? study?.is_open,
        };

        if (idStudy) {
          const { data } = await axiosInstance.post(`sc/study/${id}`, params);
          setStudy(data);
        } else {
          const { data } = await axiosInstance.put('sc/study', params);
          setStudy(data);
          setIdStudy(data.guid);
          window.history.replaceState(null, '', `/study/${data.guid}`);
        }
        setLoading(false);
        toast.success('Saved');
      } catch (error) {
        setLoading(false);
        toast.error(error?.response?.data?.message || 'Error');
      }
    },
    [studyContent, study, idStudy, axiosInstance],
  );

  const onCancel = () => {
    history.push('/study');
  };

  const onToggleStudy = () => {
    onSaveStudy({ is_open: !study?.is_open });
  };

  useEffect(() => {
    if (id && axiosInstance) {
      fetchStudyData();
    }
  }, [axiosInstance]);

  return (
    <div className="update-study-container">
      <PageHeader
        guid={study?.guid}
        defaultName={study?.name}
        dash={
          <UpdateStudyDash
            id={id}
            study={study}
            onToggleStudy={onToggleStudy}
          />
        }
        editor={
          <UpdateStudyEditor
            studyContent={studyContent}
            setStudyContent={setStudyContent}
            loading={isLoading}
          />
        }
        defaultTab={tab || ButtonType.DASH}
        showLifecycle
        defaultLifecycle={study?.lifecycle}
        isLoading={isLoading}
        onSave={onSaveStudy}
        onCancel={onCancel}
      />
    </div>
  );
};
