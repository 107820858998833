import React, { useMemo } from 'react';
import { Chart, Geom, Axis, Annotation } from 'bizcharts';
import moment from 'dayjs';

const getChangedColor = value => {
  if (value === 0) return '#adad01';
  if (value > 0) return 'green';
  return 'red';
};

const getChangedSymbol = value => {
  if (value === 0) return '';
  if (value > 0) return '↑';
  return '↓';
};

const NUMBER_OF_TICK = 4;
const MAXIMUM_TICK = 10;

const OverviewChart = ({
  width,
  changedNumber = 0,
  data = [],
  isHour,
  title,
}) => {
  if (data.length === 0) return null;

  const mapData = useMemo(
    () =>
      data.map(({ date, value }) => {
        const formatedDate = moment(date).format(
          isHour ? 'HH:mm' : 'YYYY-MM-DD',
        );
        return {
          date: formatedDate,
          value,
        };
      }),
    [data, isHour],
  );

  const maxTick = useMemo(() => {
    const listValue = mapData.map(({ value }) => value);
    return (
      MAXIMUM_TICK * Math.ceil((Math.max(...listValue) + 1) / MAXIMUM_TICK)
    );
  }, [mapData]);

  const total = useMemo(
    () => mapData.reduce((current, { value }) => current + value, 0),
    [mapData],
  );

  const stick = maxTick / NUMBER_OF_TICK;

  const textPositionX = mapData.length > 2 ? mapData.length - 2 : 0;
  const textPositionY = maxTick + stick / 5;
  const scale = useMemo(
    () => ({
      value: {
        type: 'linear',
        formatter: value => value,
        tickCount: NUMBER_OF_TICK,
        ticks: [
          '0',
          ...Array(4)
            .fill(0)
            .map((_, index) => ((index + 1) * stick).toString()),
        ],
      },
    }),
    [maxTick],
  );

  return (
    <div className="overview-chart-child-container">
      <div className="overview-chart-title">
        {title} <span>{total}</span>
      </div>
      <Chart
        width={width}
        height={(width * 2) / 3}
        data={mapData}
        scale={scale}
        padding={[30, 40]}
      >
        <Axis name="date" />
        <Axis name="value" />
        <Geom type="line" position="date*value" shape="smooth" />
        <Annotation.Text
          position={[textPositionX, textPositionY]}
          content={`${getChangedSymbol(changedNumber)} ${changedNumber}%`}
          style={{
            fill: getChangedColor(changedNumber),
            fontSize: 14,
          }}
        />
      </Chart>
    </div>
  );
};

export default OverviewChart;
