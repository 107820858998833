import React, { useEffect, useState } from 'react';
import { Container } from '@inlet/react-pixi';
import Tile from './Tile';
import { findTileSet } from './utils';

export default ({ layer, tileSets }) => {
  const [tiles, setTiles] = useState([]);
  useEffect(() => {
    const newTiles = [];
    for (let y = 0; y < layer.map.height; y += 1) {
      for (let x = 0; x < layer.map.width; x += 1) {
        const i = x + y * layer.map.width;
        if (layer.tiles[i] && layer.tiles[i].gid && layer.tiles[i].gid !== 0) {
          const tileSet = findTileSet(layer.tiles[i].gid, tileSets);
          if (tileSet) {
            newTiles.push(
              <Tile
                key={i}
                tile={layer.tiles[i]}
                tileSet={tileSet}
                horizontalFlip={layer.horizontalFlips[i]}
                verticalFlip={layer.verticalFlips[i]}
                diagonalFlip={layer.diagonalFlips[i]}
                x={x * layer.map.tileWidth + (tileSet?.tileOffset?.x ?? 0)}
                y={
                  (y + 1) * layer.map.tileHeight + (tileSet?.tileOffset?.y ?? 0)
                }
                animationSpeed={1}
                isPlaying
              />,
            );
          }
        }
      }
    }
    setTiles(newTiles);
  }, [layer, tileSets]);
  return <Container alpha={layer.opacity}>{tiles}</Container>;
};
