import React, { useEffect, useState } from 'react';
import { useAxios } from 'utils/hooks';
import { NEUX_URL } from 'utils/constants';
import { CircularProgress } from '@material-ui/core';
import SequenceItem from 'components/SequenceItem';
import './TestSequence.scss';

const TestSequence = () => {
  const [loading, setLoading] = useState(false);
  const [sequences, setSequences] = useState([]);
  const axiosInstance = useAxios(NEUX_URL);

  const fetchData = async () => {
    try {
      setLoading(true);
      const resp = await axiosInstance.get('/sc/sequence/search');
      if (resp?.data?.sequences) {
        setSequences(resp.data.sequences);
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (axiosInstance) {
      fetchData();
    }
  }, [axiosInstance]);

  return (
    <>
      <div id="game-outside">
        {loading ? (
          <div className="sequence-item-loading">
            <CircularProgress />
          </div>
        ) : (
          <div className="sequence-item-list">
            {sequences.map(seq => (
              <SequenceItem sequence={seq} key={seq.guid} />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default TestSequence;
