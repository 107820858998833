import React, { useEffect } from 'react';
import { useAxios } from 'utils/hooks';
import { NEUX_URL } from 'utils/constants';
import DataTable from 'react-data-table-component';
import './Data.scss';

const columns = [
  {
    name: 'GUID',
    selector: 'guid',
  },
  {
    name: 'NAME',
    selector: 'name',
  },
  {
    name: 'CREATED_UTC',
    selector: 'created_utc',
  },
  {
    name: 'UPDATED_UTC',
    selector: 'updated_utc',
  },
];

export default () => {
  const [loading, setLoading] = React.useState(false);
  const [listData, setListData] = React.useState([]);
  const axiosInstance = useAxios(NEUX_URL);
  /* eslint-disable-next-line no-unused-vars */
  const fetchGameData = async () => {
    try {
      setLoading(true);
      const resp = await axiosInstance.get('/sc/datas/search');
      if (resp && resp.data && resp.data.datas) {
        setListData(resp.data.datas);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (axiosInstance) {
      // fetchGameData();
    }
  }, [axiosInstance]);

  return (
    <DataTable
      title="Games Data"
      columns={columns}
      data={listData}
      className="data-table"
      pagination
      paginationDefaultPage={1}
      striped
      style={{
        cursor: 'pointer',
      }}
      progressPending={loading}
      paginationPerPage={50}
      paginationRowsPerPageOptions={[50]}
    />
  );
};
