import React, { useState, useEffect, memo, useCallback, useRef } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Fade } from '@material-ui/core';
import Tiled from 'tiled';
import { formatTimingUrlWithNeux, formatMapUrlWithNeux } from 'utils/format';
import ToneTransport from 'services/ToneTransport';
import { useAxios } from 'utils/hooks';
import { NEUX_URL } from 'utils/constants';
import './PlaySequence.scss';

const PlaySequence = ({
  map,
  timing,
  questions,
  isKeepingGameMode,
  clearSequenceChoice,
  handleCloseGame,
  questionText,
  question,
  trackSliderObject,
}) => {
  const [isPlay, setIsPlay] = useState(false);
  const [mapUrl, setMapUrl] = useState(null);
  const [timingUrl, setTimingUrl] = useState(null);
  const eventId = useRef();
  const axiosInstance = useAxios(NEUX_URL); // see https://github.com/panz3r/jwt-checker-server for a quick implementation
  const hideMenuTab = () => {
    document.getElementById('main-navbar').style.display = 'none';
    document.getElementsByClassName('main-menu')[0].style.display = 'none';
    document.getElementsByClassName('app-content')[0].style.marginLeft = '0px';
    document.getElementsByClassName(
      'cabinet-layout-container',
    )[0].style.paddingTop = '0px';
    document.getElementsByClassName('fixed-navbar')[0].style.paddingTop = '0px';
    document.getElementsByClassName('content-wrapper')[0].style.padding = '0px';
    document.getElementsByClassName('content-wrapper')[0].style.height =
      '100vh';
    document.getElementsByClassName('content-wrapper')[0].style.overflow =
      'hidden';
    document.getElementsByClassName('content-wrapper')[0].style.background =
      '#000000';
  };

  const openMenuTab = () => {
    document.getElementById('main-navbar').style.display = 'block';
    document.getElementsByClassName('main-menu')[0].style.display = 'block';
    document.getElementsByClassName('main-menu')[0].style.top = '4.57rem';
    document.getElementsByClassName('app-content')[0].style.marginLeft =
      '240px';
    document.body.style.paddingTop = '4.57rem';

    if (document.getElementsByClassName('content-wrapper')[0]) {
      document.getElementsByClassName('content-wrapper')[0].style.padding =
        '2.2rem';
      document.getElementsByClassName('content-wrapper')[0].style.height =
        'calc(100vh - 84px)';
      document.getElementsByClassName('content-wrapper')[0].style.overflow =
        'auto';
      document.getElementsByClassName('content-wrapper')[0].style.background =
        'initial';
    }
  };

  const resetGameVariable = () => {
    setIsPlay(false);
    openMenuTab();
    setMapUrl(null);
    setTimingUrl(null);
  };

  const handlePlayRequest = useCallback(
    async ({ _mapUrl, _timingUrl, _questionUrl }) => {
      if (!_mapUrl) {
        setIsPlay(false);
        if (!isKeepingGameMode) {
          openMenuTab();
        }
        ToneTransport.getInstance().clearSchedule(eventId.current);
        handleCloseGame();
        setMapUrl(null);
        setTimingUrl(null);
      } else {
        setIsPlay(true);
        hideMenuTab();

        setTimingUrl(formatTimingUrlWithNeux(_timingUrl));
        if (_mapUrl) {
          setMapUrl(formatMapUrlWithNeux(_mapUrl));
        }

        if (_timingUrl) {
          try {
            const { data } = await axiosInstance.get(
              formatTimingUrlWithNeux(_timingUrl),
            );
            if (!data) return;
            const { bpm, ...rest } = data;
            ToneTransport.getInstance().clearEventEnding();
            eventId.current = ToneTransport.getInstance().startEventEnding(
              rest,
              () => {
                if (_questionUrl) {
                  /* eslint-disable-next-line no-use-before-define */
                  clearSequenceChoice();
                }
                handleCloseGame();
              },
            );
          } catch (err) {
            console.log(err);
          }
        }
      }
    },
    [isKeepingGameMode, handleCloseGame],
  );

  useEffect(() => {
    if (!map) {
      setIsPlay(false);
    } else {
      setIsPlay(true);
    }
    handlePlayRequest({
      _mapUrl: map,
      _timingUrl: timing,
      _questionUrl: questions,
    });
  }, [map, timing, questions]);

  useEffect(() => {
    return () => {
      resetGameVariable();
    };
  }, []);

  return (
    <>
      <CssBaseline />
      <Fade
        // in={openQuestion}
        in={Boolean(isPlay)}
        timeout={{
          enter: 1000,
          exit: 0,
        }}
        mountOnEnter
        unmountOnExit
      >
        <div className="tileWrapper">
          {isPlay && (
            <Tiled
              mapUrl={mapUrl}
              timingUrl={timingUrl}
              axiosInstance={axiosInstance}
              tileText={questionText}
              trackSliderObject={trackSliderObject}
              currentQuestion={question}
            />
          )}
        </div>
      </Fade>
    </>
  );
};

export default memo(PlaySequence);
